import React, { useState, useEffect } from 'react';
import { useWeb3 } from '../contexts/Web3Context';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Wallet, 
  RefreshCw, 
  ArrowDownCircle, 
  ArrowUpCircle, 
  Copy, 
  CheckCircle2,
  AlertCircle,
  Coins,
  Trophy,
  Clock,
  Users,
  Target
} from 'lucide-react';
import { useApi } from '../hooks/useApi';
import WalletWarning from './WalletWarning';

const UserAccount = () => {
  const { isConnected, address } = useWeb3();
  const [tokenBalance, setTokenBalance] = useState('0');
  const [depositAddress, setDepositAddress] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isProcessingWithdraw, setIsProcessingWithdraw] = useState(false);
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('deposit'); // 'deposit', 'withdraw', or 'bets'
  const [bets, setBets] = useState([]);
  const [isLoadingBets, setIsLoadingBets] = useState(false);
  const [isProcessingDeposit, setIsProcessingDeposit] = useState(false);
  const { generateDepositWallet, processDeposit, withdrawTokens, getBalance, getWalletBets } = useApi();

  // Function to fetch balance
  const fetchBalance = async () => {
    try {
      const response = await getBalance(address);
      if (response.status === 200) {
        setTokenBalance(response.balance || '0');
      }
    } catch (error) {
      console.error('Failed to fetch balance:', error);
    }
  };

  // Fetch initial balance
  useEffect(() => {
    if (address) {
      fetchBalance();
    }
  }, [address]);

  // Function to refresh balance
  const handleRefreshBalance = async () => {
    try {
      setIsRefreshingBalance(true);
      const response = await getBalance(address);
      if (response.status === 200) {
        setTokenBalance(response.balance || '0');
      }
    } catch (error) {
      console.error('Failed to refresh balance:', error);
    } finally {
      setIsRefreshingBalance(false);
    }
  };

  // Generate deposit wallet
  const handleGenerateDepositWallet = async () => {
    try {
      const response = await generateDepositWallet(address);
      if (response.status === 200) {
        setDepositAddress(response.deposit_wallet);
      }
    } catch (error) {
      console.error('Failed to generate deposit wallet:', error);
    }
  };

  // Handle withdrawal
  const handleWithdraw = async () => {
    if (!withdrawAmount || isNaN(withdrawAmount) || parseFloat(withdrawAmount) <= 0) {
      alert('Please enter a valid withdrawal amount');
      return;
    }

    try {
      setIsProcessingWithdraw(true);
      const response = await withdrawTokens(address, parseFloat(withdrawAmount), process.env.REACT_APP_BACK_TOKEN_ADDRESS);
      if (response.status === 200) {
        await fetchBalance();
        setWithdrawAmount('');
      }
    } catch (error) {
      console.error('Failed to process withdrawal:', error);
    } finally {
      setIsProcessingWithdraw(false);
    }
  };

  // Copy deposit address to clipboard
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(depositAddress);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  // Fetch betting history
  const fetchBets = async () => {
    if (!address) return;
    
    try {
      setIsLoadingBets(true);
      const response = await getWalletBets(address);
      if (response.status === 200) {
        setBets(response.bets || []);
      }
    } catch (error) {
      console.error('Failed to fetch bets:', error);
    } finally {
      setIsLoadingBets(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'bets') {
      fetchBets();
    }
  }, [activeTab, address]);

  // Handle deposit processing
  const handleProcessDeposit = async () => {
    try {
      setIsProcessingDeposit(true);
      const response = await processDeposit(address, process.env.REACT_APP_BACK_TOKEN_ADDRESS);
      if (response.status === 200) {
        await fetchBalance();
      }
    } catch (error) {
      console.error('Failed to process deposit:', error);
    } finally {
      setIsProcessingDeposit(false);
    }
  };

  if (!isConnected) {
    return <WalletWarning />;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-white mb-4 flex items-center justify-center gap-3">
          <Wallet className="w-8 h-8 text-purple-500" />
          Account Management
        </h1>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Manage your $BACK tokens, make deposits, and process withdrawals.
        </p>
      </div>

      {/* Balance Card */}
      <div className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 mb-8 border border-purple-500/20">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-gray-400 text-sm mb-1">Total Balance</h2>
            <div className="text-3xl font-bold text-white">
              {tokenBalance} <span className="text-purple-400">$BACK</span>
            </div>
          </div>
          <button
            onClick={handleRefreshBalance}
            disabled={isRefreshingBalance}
            className="p-2 bg-purple-500/10 hover:bg-purple-500/20 text-purple-400 rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50"
          >
            {isRefreshingBalance ? (
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              >
                <RefreshCw size={20} />
              </motion.div>
            ) : (
              <RefreshCw size={20} />
            )}
            <span className="hidden sm:inline">Refresh</span>
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex space-x-2 mb-6">
        <button
          onClick={() => setActiveTab('deposit')}
          className={`flex-1 p-4 rounded-xl transition-all ${
            activeTab === 'deposit'
              ? 'bg-purple-500 text-white'
              : 'bg-gray-800/50 text-gray-400 hover:bg-gray-800'
          }`}
        >
          <div className="flex items-center justify-center gap-2">
            <ArrowDownCircle size={20} />
            Deposit
          </div>
        </button>
        <button
          onClick={() => setActiveTab('withdraw')}
          className={`flex-1 p-4 rounded-xl transition-all ${
            activeTab === 'withdraw'
              ? 'bg-purple-500 text-white'
              : 'bg-gray-800/50 text-gray-400 hover:bg-gray-800'
          }`}
        >
          <div className="flex items-center justify-center gap-2">
            <ArrowUpCircle size={20} />
            Withdraw
          </div>
        </button>
        <button
          onClick={() => setActiveTab('bets')}
          className={`flex-1 p-4 rounded-xl transition-all ${
            activeTab === 'bets'
              ? 'bg-purple-500 text-white'
              : 'bg-gray-800/50 text-gray-400 hover:bg-gray-800'
          }`}
        >
          <div className="flex items-center justify-center gap-2">
            <Coins size={20} />
            Bets
          </div>
        </button>
      </div>

      {/* Content Area */}
      <div className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20">
        {activeTab === 'deposit' && (
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
              <ArrowDownCircle className="text-purple-400" />
              Deposit $BACK
            </h3>
            {depositAddress ? (
              <div className="space-y-4">
                <div className="bg-gray-900/50 p-4 rounded-xl">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-400">Your deposit address:</span>
                    <button
                      onClick={handleCopyAddress}
                      className="text-purple-400 hover:text-purple-300 transition-colors"
                    >
                      {isCopied ? <CheckCircle2 size={20} /> : <Copy size={20} />}
                    </button>
                  </div>
                  <div className="mt-2 font-mono text-white break-all">
                    {depositAddress}
                  </div>
                  <div className="mt-4 flex justify-end">
                    <button
                      onClick={handleProcessDeposit}
                      disabled={isProcessingDeposit}
                      className="bg-purple-500/20 hover:bg-purple-500/30 text-purple-300 px-4 py-2 rounded-lg transition-all flex items-center gap-2 text-sm"
                    >
                      {isProcessingDeposit ? (
                        <>
                          <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                          >
                            <RefreshCw size={16} />
                          </motion.div>
                          Processing Deposit...
                        </>
                      ) : (
                        <>
                          <RefreshCw size={16} />
                          Process Deposit
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-xl p-4">
                  <div className="flex items-start gap-3">
                    <AlertCircle className="text-yellow-500 shrink-0 mt-1" />
                    <div className="space-y-2">
                      <div className="text-yellow-200 text-sm">
                        Please send both $BACK tokens and SOL to this address:
                      </div>
                      <ul className="list-disc list-inside text-sm text-yellow-200 ml-2">
                        <li>The amount of $BACK tokens you wish to deposit</li>
                        <li>At least 0.01 SOL for transaction fees</li>
                      </ul>
                      <div className="text-yellow-200/80 text-sm italic">
                        Sending insufficient SOL or any other tokens may result in permanent loss.
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleRefreshBalance}
                  disabled={isRefreshingBalance}
                  className="w-full bg-purple-500 hover:bg-purple-600 text-white font-semibold py-3 px-4 rounded-xl transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
                >
                  {isRefreshingBalance ? (
                    <>
                      <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                      >
                        <RefreshCw size={20} />
                      </motion.div>
                      Processing...
                    </>
                  ) : (
                    <>
                      <RefreshCw size={20} />
                      Refresh Balance
                    </>
                  )}
                </button>
              </div>
            ) : (
              <button
                onClick={handleGenerateDepositWallet}
                className="w-full bg-purple-500 hover:bg-purple-600 text-white font-semibold py-3 px-4 rounded-xl transition-colors"
              >
                Generate Deposit Address
              </button>
            )}
          </div>
        )}

        {activeTab === 'withdraw' && (
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
              <ArrowUpCircle className="text-purple-400" />
              Withdraw $BACK
            </h3>
            <div className="space-y-4">
              <div className="bg-blue-500/10 border border-blue-500/20 rounded-xl p-4 mb-4">
                <div className="flex items-start gap-3">
                  <AlertCircle className="text-blue-400 shrink-0 mt-1" />
                  <div className="text-blue-200 text-sm">
                    Funds will be withdrawn to your connected wallet address: 
                    <div className="mt-1 font-mono text-blue-300 break-all">
                      {address}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Amount to Withdraw
                </label>
                <input
                  type="number"
                  value={withdrawAmount}
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  placeholder="Enter amount"
                  className="w-full bg-gray-900/50 text-white p-3 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all"
                />
              </div>
              <button
                onClick={handleWithdraw}
                disabled={isProcessingWithdraw || !withdrawAmount}
                className="w-full bg-purple-500 hover:bg-purple-600 text-white font-semibold py-3 px-4 rounded-xl transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
              >
                {isProcessingWithdraw ? (
                  <>
                    <motion.div
                      animate={{ rotate: 360 }}
                      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    >
                      <RefreshCw size={20} />
                    </motion.div>
                    Processing...
                  </>
                ) : (
                  'Withdraw'
                )}
              </button>
            </div>
          </div>
        )}

        {activeTab === 'bets' && (
          <div className="space-y-6">
            <div className="flex items-center justify-between mb-8">
              <h3 className="text-xl font-semibold text-white flex items-center gap-2">
                <Coins className="text-purple-400" />
                Your Bets
              </h3>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={fetchBets}
                disabled={isLoadingBets}
                className="px-4 py-2 bg-purple-500/10 hover:bg-purple-500/20 text-purple-400 rounded-lg transition-colors flex items-center gap-2"
              >
                <motion.div
                  animate={isLoadingBets ? { rotate: 360 } : {}}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                >
                  <RefreshCw size={18} />
                </motion.div>
                Refresh
              </motion.button>
            </div>
            
            {isLoadingBets ? (
              <div className="flex items-center justify-center py-20">
                <motion.div
                  className="relative"
                  animate={{ rotate: 360 }}
                  transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
                >
                  <motion.div
                    className="absolute inset-0"
                    animate={{
                      boxShadow: [
                        "0 0 20px rgba(139, 92, 246, 0.3)",
                        "0 0 40px rgba(139, 92, 246, 0.6)",
                        "0 0 20px rgba(139, 92, 246, 0.3)"
                      ]
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  />
                  <Coins size={40} className="text-purple-400" />
                </motion.div>
              </div>
            ) : bets.length > 0 ? (
              <div className="space-y-4">
                {bets.map((bet, index) => (
                  <motion.div
                    key={bet.bet_backend_id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="relative group"
                  >
                    {/* Animated gradient background */}
                    <motion.div
                      className="absolute -inset-[1px] bg-gradient-to-r from-purple-500/50 via-blue-500/50 to-purple-500/50 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"
                      animate={{
                        backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
                      }}
                      transition={{ duration: 5, repeat: Infinity, ease: 'linear' }}
                    />
                    
                    <div className="relative bg-gray-900/90 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30 overflow-hidden">
                      {/* Animated glow effect */}
                      <motion.div
                        className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-blue-500/10 to-purple-500/10"
                        animate={{
                          x: ['-100%', '100%'],
                        }}
                        transition={{
                          duration: 10,
                          repeat: Infinity,
                          ease: 'linear',
                        }}
                      />
                      
                      <div className="relative z-10">
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-6">
                          <div>
                            <div className="text-purple-300 text-sm mb-2 font-['Space_Grotesk']">BET AMOUNT</div>
                            <motion.div 
                              className="text-white font-bold text-xl flex items-center gap-2"
                              whileHover={{ scale: 1.05 }}
                              transition={{ type: "spring", stiffness: 400, damping: 10 }}
                            >
                              <Coins size={20} className="text-yellow-400" />
                              {bet.bet_amount} <span className="text-purple-400 font-normal">$BACK</span>
                            </motion.div>
                          </div>
                          
                          <div>
                            <div className="text-purple-300 text-sm mb-2 font-['Space_Grotesk']">STATUS</div>
                            <motion.div 
                              className="flex items-center gap-2"
                              whileHover={{ scale: 1.05 }}
                              transition={{ type: "spring", stiffness: 400, damping: 10 }}
                            >
                              <div className={`px-3 py-1 rounded-full flex items-center gap-2 ${
                                bet.bet_active 
                                  ? 'bg-yellow-500/20 text-yellow-400 border border-yellow-500/30' 
                                  : bet.payout_amount 
                                    ? 'bg-green-500/20 text-green-400 border border-green-500/30'
                                    : 'bg-red-500/20 text-red-400 border border-red-500/30'
                              }`}>
                                <motion.div
                                  animate={bet.bet_active ? { scale: [1, 1.2, 1] } : {}}
                                  transition={{ duration: 2, repeat: Infinity }}
                                  className="w-2 h-2 rounded-full bg-current"
                                />
                                <span className="font-semibold tracking-wider text-sm">
                                  {bet.bet_active ? 'ACTIVE' : bet.payout_amount ? 'WON' : 'LOST'}
                                </span>
                              </div>
                            </motion.div>
                          </div>
                          
                          <div>
                            <div className="text-purple-300 text-sm mb-2 font-['Space_Grotesk']">ROOM</div>
                            <motion.div 
                              className="text-white font-bold text-xl flex items-center gap-2"
                              whileHover={{ scale: 1.05 }}
                              transition={{ type: "spring", stiffness: 400, damping: 10 }}
                            >
                              <Users size={20} className="text-blue-400" />
                              #{bet.room_id}
                            </motion.div>
                          </div>
                          
                          <div>
                            <div className="text-purple-300 text-sm mb-2 font-['Space_Grotesk']">DATE</div>
                            <motion.div 
                              className="text-white font-bold flex items-center gap-2"
                              whileHover={{ scale: 1.05 }}
                              transition={{ type: "spring", stiffness: 400, damping: 10 }}
                            >
                              <Clock size={20} className="text-purple-400" />
                              {new Date(bet.bet_timestamp * 1000).toLocaleDateString()}
                            </motion.div>
                          </div>
                        </div>

                        {bet.payout_amount && (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="mt-4 pt-4 border-t border-gray-700/30"
                          >
                            <div className="flex items-center gap-2 text-green-400">
                              <Trophy size={20} />
                              <span className="font-bold">
                                Payout: {bet.payout_amount} $BACK
                              </span>
                            </div>
                          </motion.div>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="relative bg-gray-900/50 backdrop-blur-xl rounded-xl p-12 text-center border border-purple-500/20"
              >
                <motion.div
                  className="absolute inset-0"
                  animate={{
                    background: [
                      "radial-gradient(circle at 20% 20%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)",
                      "radial-gradient(circle at 80% 80%, rgba(56, 189, 248, 0.15) 0%, transparent 50%)",
                      "radial-gradient(circle at 20% 80%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)",
                    ]
                  }}
                  transition={{ duration: 10, repeat: Infinity }}
                />
                
                <div className="relative">
                  <motion.div
                    animate={{ 
                      scale: [1, 1.1, 1],
                      rotate: [0, 5, -5, 0]
                    }}
                    transition={{ duration: 4, repeat: Infinity }}
                    className="inline-block mb-6"
                  >
                    <motion.div
                      className="p-6 bg-purple-500/20 rounded-full"
                      animate={{
                        boxShadow: [
                          "0 0 20px rgba(139, 92, 246, 0.3)",
                          "0 0 40px rgba(139, 92, 246, 0.6)",
                          "0 0 20px rgba(139, 92, 246, 0.3)"
                        ]
                      }}
                      transition={{ duration: 2, repeat: Infinity }}
                    >
                      <Trophy className="w-12 h-12 text-purple-400" />
                    </motion.div>
                  </motion.div>
                  
                  <motion.h3
                    className="text-2xl font-bold text-white mb-3 font-['Space_Grotesk']"
                    animate={{
                      opacity: [0.7, 1, 0.7],
                    }}
                    transition={{ duration: 3, repeat: Infinity }}
                  >
                    No Bets Yet
                  </motion.h3>
                  
                  <p className="text-purple-400 font-['Space_Grotesk']">
                    Start betting on players to earn rewards and climb the leaderboard!
                  </p>
                </div>
              </motion.div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAccount;