import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Plus, Flame, Trophy, Sparkles, Diamond, Clock, Coins } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import CreateHouseModal from './CreateHouseModal';
import angel from "../assets/xpaHutel.jpg";
import Loading from './Loading';

const ITEMS_PER_PAGE = 6;

const StatusFilter = ({ currentStatus, onStatusChange }) => (
  <div className="flex gap-4 mb-8">
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => onStatusChange('in_progress')}
      className={`px-6 py-2 rounded-lg flex items-center gap-2 ${
        currentStatus === 'in_progress'
          ? 'bg-green-500/20 text-green-400 border border-green-500/30'
          : 'bg-gray-800/50 text-gray-400 border border-gray-700/30 hover:bg-gray-700/50'
      }`}
    >
      <Flame className="w-4 h-4" />
      <span className="font-['Space_Grotesk']">Live</span>
    </motion.button>
    
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => onStatusChange('arena')}
      className={`px-6 py-2 rounded-lg flex items-center gap-2 ${
        currentStatus === 'arena'
          ? 'bg-orange-500/20 text-orange-400 border border-orange-500/30'
          : 'bg-gray-800/50 text-gray-400 border border-gray-700/30 hover:bg-gray-700/50'
      }`}
    >
      <Trophy className="w-4 h-4" />
      <span className="font-['Space_Grotesk']">Competing</span>
    </motion.button>
    
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => onStatusChange('expired')}
      className={`px-6 py-2 rounded-lg flex items-center gap-2 ${
        currentStatus === 'expired'
          ? 'bg-gray-600/20 text-gray-400 border border-gray-600/30'
          : 'bg-gray-800/50 text-gray-400 border border-gray-700/30 hover:bg-gray-700/50'
      }`}
    >
      <Clock className="w-4 h-4" />
      <span className="font-['Space_Grotesk']">Expired</span>
    </motion.button>
  </div>
);

const getTimeRemaining = (expirationDate) => {
  const total = Date.parse(expirationDate) - Date.now();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  
  return {
    total,
    days,
    hours,
    minutes,
    seconds
  };
};

const CountdownTimer = ({ expirationDate }) => {
  const [timeLeft, setTimeLeft] = useState(getTimeRemaining(expirationDate));

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = getTimeRemaining(expirationDate);
      setTimeLeft(remaining);
      if (remaining.total <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expirationDate]);

  if (timeLeft.total <= 0) {
    return <span className="text-red-400">Expired</span>;
  }

  return (
    <div className="text-sm font-['Space_Grotesk']">
      {timeLeft.days > 0 && <span className="text-purple-400">{timeLeft.days}d </span>}
      <span className="text-blue-400">{timeLeft.hours}h </span>
      <span className="text-purple-400">{timeLeft.minutes}m </span>
      <span className="text-blue-400">{timeLeft.seconds}s</span>
    </div>
  );
};

const HouseCard = ({ house, onHouseClick }) => {
  const getStatusStyles = () => {
    switch (house.task_house_status) {
      case 'arena':
        return {
          container: "relative group cursor-pointer overflow-hidden rounded-xl bg-gradient-to-br from-orange-900/40 to-red-900/40 backdrop-blur-xl border border-orange-500/20",
          glow: "absolute -inset-0.5 bg-gradient-to-r from-orange-600 to-red-600 rounded-xl opacity-0 group-hover:opacity-100 blur",
          icon: "w-5 h-5 text-orange-400",
          status: "text-orange-400",
          statusDot: "bg-orange-400",
          iconBg: "bg-orange-500/30 border-orange-500/20"
        };
      case 'expired':
        return {
          container: "relative group cursor-pointer overflow-hidden rounded-xl bg-gradient-to-br from-gray-900/40 to-gray-800/40 backdrop-blur-xl border border-gray-700/20 opacity-75",
          glow: "absolute -inset-0.5 bg-gradient-to-r from-gray-700 to-gray-600 rounded-xl opacity-0 group-hover:opacity-50 blur",
          icon: "w-5 h-5 text-gray-400",
          status: "text-gray-400",
          statusDot: "bg-gray-400",
          iconBg: "bg-gray-500/30 border-gray-500/20"
        };
      default: // in_progress
        return {
          container: `relative group cursor-pointer overflow-hidden rounded-xl ${
            house.is_bettable 
              ? "bg-gradient-to-br from-yellow-900/40 to-purple-900/40 backdrop-blur-xl border border-yellow-500/20"
              : "bg-gradient-to-br from-purple-900/40 to-blue-900/40 backdrop-blur-xl border border-purple-500/20"
          }`,
          glow: `absolute -inset-0.5 ${
            house.is_bettable
              ? "bg-gradient-to-r from-yellow-600 to-purple-600"
              : "bg-gradient-to-r from-purple-600 to-blue-600"
          } rounded-xl opacity-0 group-hover:opacity-100 blur`,
          icon: house.is_bettable ? "w-5 h-5 text-yellow-400" : "w-5 h-5 text-purple-400",
          status: "text-green-400",
          statusDot: "bg-green-400",
          iconBg: house.is_bettable ? "bg-yellow-500/30 border-yellow-500/20" : "bg-purple-500/30 border-purple-500/20"
        };
    }
  };

  const styles = getStatusStyles();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ 
        scale: 1.05,
        rotateY: 5,
        boxShadow: house.is_bettable 
          ? "0 0 30px rgba(234, 179, 8, 0.3)"
          : house.task_house_status === 'arena' 
            ? "0 0 30px rgba(249, 115, 22, 0.3)"
            : "0 0 30px rgba(139, 92, 246, 0.3)"
      }}
      onClick={() => onHouseClick(house.task_house_id)}
      className={styles.container}
    >
      {/* Floating particles effect */}
      <motion.div 
        className="absolute inset-0 z-0"
        animate={{
          background: house.is_bettable ? [
            'radial-gradient(circle at 20% 20%, rgba(234, 179, 8, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 80% 80%, rgba(168, 85, 247, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 20% 80%, rgba(234, 179, 8, 0.15) 0%, transparent 50%)',
          ] : [
            'radial-gradient(circle at 20% 20%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 80% 80%, rgba(56, 189, 248, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 20% 80%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)',
          ]
        }}
        transition={{ duration: 5, repeat: Infinity }}
      />

      {/* Glowing border on hover */}
      <motion.div
        className={styles.glow}
        initial={{ opacity: 0 }}
        whileHover={{ opacity: 0.5 }}
        transition={{ duration: 0.3 }}
      />

      {/* Content */}
      <div className="relative z-20 p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <motion.div 
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
              className={`p-2 rounded-lg backdrop-blur-sm border ${styles.iconBg}`}
            >
              {house.is_bettable ? <Coins className={styles.icon} /> : <Diamond className={styles.icon} />}
            </motion.div>
            <div>
              <div className="flex items-center gap-2">
                <h3 className="text-xl text-white font-['Space_Grotesk'] font-bold tracking-wide">
                  {house.task_house_name}
                </h3>
                {house.is_bettable && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                    className="px-2 py-1 rounded-full bg-yellow-500/20 border border-yellow-500 flex items-center gap-1"
                  >
                    <Coins className="w-3 h-3 text-yellow-500" />
                    <span className="text-yellow-500 text-xs font-['Space_Grotesk'] uppercase tracking-wider">Bettable</span>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Stats display */}
        <div className="grid grid-cols-2 gap-2 mb-4">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className={`bg-purple-500/10 rounded-lg p-2 text-center border border-purple-500/20 ${
              house.task_house_status === 'arena' ? 'bg-orange-500/10 border-orange-500/20' : ''
            }`}
          >
            <div className={`text-xs ${house.task_house_status === 'arena' ? 'text-orange-400' : 'text-purple-400'}`}>
              ROOMS
            </div>
            <div className="text-white font-bold font-['Space_Grotesk']">
              {house.rooms_count}
            </div>
          </motion.div>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="bg-blue-500/10 rounded-lg p-2 text-center border border-blue-500/20"
          >
            <div className="text-blue-400 text-xs">TIME LEFT</div>
            <CountdownTimer expirationDate={house.expiration_date} />
          </motion.div>
        </div>

        <div className="bg-gray-900/50 backdrop-blur-sm rounded-lg p-4 border border-gray-700/30">
          <p className="text-gray-300 font-['Chakra_Petch'] tracking-wide text-sm line-clamp-2">
            {house.task_description}
          </p>
        </div>

        <motion.div 
          className="mt-4 flex justify-between items-center"
          whileHover={{ x: 5 }}
        >
          <div className="flex items-center gap-2">
            <motion.div 
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 1, repeat: Infinity }}
              className={`w-2 h-2 rounded-full ${styles.statusDot}`}
            />
            <span className={`text-sm font-['Space_Grotesk'] ${styles.status}`}>
              {house.task_house_status === 'in_progress' ? 'LIVE' : 
               house.task_house_status === 'arena' ? 'COMPETING' : 'EXPIRED'}
            </span>
          </div>
          {house.task_house_status !== 'expired' && (
            <motion.span 
              className={`text-sm font-['Space_Grotesk'] flex items-center gap-1 ${
                house.task_house_status === 'arena' ? 'text-orange-400' : 'text-purple-400'
              }`}
              whileHover={{ x: 5 }}
            >
              ENTER <Sparkles className="w-4 h-4" />
            </motion.span>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};

const Explorer = () => {
  const [houses, setHouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('in_progress');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [enteringHouse, setEnteringHouse] = useState(null);
  const { listHouses } = useApi();
  const navigate = useNavigate();

  console.log(houses)

  const fetchHouses = async () => {
    try {
      setLoading(true);
      const response = await listHouses();
      console.log(response)
      setHouses(response.task_houses || []);
    } catch (error) {
      console.error('Failed to load houses:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHouses();
  }, []);

  const handleHouseClick = async (houseId) => {
    const house = houses.find(h => h.task_house_id === houseId);
    setEnteringHouse(house);
    
    // Delay navigation to show the animation
    setTimeout(() => {
      navigate(`/explorer/${houseId}`);
    }, 1500);
  };

  const sortedAndFilteredHouses = houses
    .sort((a, b) => {
      // Sort bettable houses first
      if (a.is_bettable && !b.is_bettable) return -1;
      if (!a.is_bettable && b.is_bettable) return 1;
      return 0;
    })
    .filter(house => house.task_house_status === currentStatus);

  const totalPages = Math.ceil(sortedAndFilteredHouses.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentHouses = sortedAndFilteredHouses.slice(startIndex, endIndex);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when status changes
  }, [currentStatus]);

  if (loading) {
    return <Loading />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900/10 to-gray-900"
    >
      {/* Success Message Overlay */}
      <AnimatePresence>
        {enteringHouse && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="relative"
            >
              {/* Pulsing background effect */}
              <motion.div
                className="absolute inset-0 rounded-xl"
                animate={{
                  boxShadow: [
                    "0 0 20px rgba(139, 92, 246, 0.3)",
                    "0 0 60px rgba(139, 92, 246, 0.6)",
                    "0 0 20px rgba(139, 92, 246, 0.3)"
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              
              <div className="relative bg-gray-900/90 border border-purple-500/20 rounded-xl p-8 text-center">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  className="mb-4 inline-block"
                >
                  <Sparkles className="w-12 h-12 text-purple-400" />
                </motion.div>
                
                <motion.h2
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-2xl font-bold text-white mb-2 font-['Space_Grotesk']"
                >
                  Entering Simulation House
                </motion.h2>
                
                <motion.h3
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="text-xl text-purple-400 mb-4 font-['Space_Grotesk']"
                >
                  {enteringHouse?.task_house_name}
                </motion.h3>
                
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.6, type: "spring" }}
                  className="flex justify-center gap-2"
                >
                  <motion.div
                    animate={{ y: [-2, 2, -2] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                  <motion.div
                    animate={{ y: [-2, 2, -2] }}
                    transition={{ duration: 1.5, repeat: Infinity, delay: 0.2 }}
                    className="w-2 h-2 bg-blue-500 rounded-full"
                  />
                  <motion.div
                    animate={{ y: [-2, 2, -2] }}
                    transition={{ duration: 1.5, repeat: Infinity, delay: 0.4 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Cosmic background effect */}
      <motion.div
        className="fixed inset-0 pointer-events-none"
        animate={{
          background: [
            'radial-gradient(circle at 0% 0%, rgba(139, 92, 246, 0.1), transparent 50%)',
            'radial-gradient(circle at 100% 100%, rgba(56, 189, 248, 0.1), transparent 50%)',
            'radial-gradient(circle at 0% 100%, rgba(139, 92, 246, 0.1), transparent 50%)',
          ]
        }}
        transition={{ duration: 10, repeat: Infinity }}
      />

      <div className="container mx-auto px-4 py-12 relative z-10">
        {/* Header section */}
        <motion.div
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          className="mb-12"
        >
          <div className="flex items-center gap-3 mb-4">
            <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 font-['Space_Grotesk']">
              Simulation Explorer
            </h1>
          </div>
          <div className="flex items-center justify-between gap-3 mb-4">
            <h1 className="text-4xl font-bold text-white font-['Space_Grotesk']">
              Explore Houses
            </h1>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsCreateModalOpen(true)}
              className="relative group px-4 py-2 rounded-lg overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600/40 to-blue-600/40 opacity-80 group-hover:opacity-100 transition-all duration-300" />
              <motion.div
                className="absolute inset-0 opacity-0 group-hover:opacity-20 transition-opacity"
                style={{
                  background: 'radial-gradient(circle at center, rgba(139, 92, 246, 0.8) 0%, transparent 70%)',
                }}
              />
              <div className="relative flex items-center gap-2 text-gray-300 group-hover:text-white transition-colors font-['Space_Grotesk'] text-sm">
                <Plus size={16} className="text-purple-400 group-hover:text-purple-300" />
                <span>NEW HOUSE</span>
              </div>
            </motion.button>
          </div>
          <p className="text-gray-400 font-['Chakra_Petch'] tracking-wide mb-8">
            Discover and join the most exciting houses
          </p>

          {/* Add StatusFilter component */}
          <StatusFilter currentStatus={currentStatus} onStatusChange={setCurrentStatus} />
        </motion.div>

        {/* Houses grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentHouses.map((house) => (
            <HouseCard
              key={house.task_house_id}
              house={house}
              onHouseClick={handleHouseClick}
            />
          ))}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="mt-12 flex justify-center items-center gap-4">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="p-2 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-300 hover:text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed border border-gray-700"
            >
              <ChevronLeft size={24} />
            </motion.button>

            <span className="text-gray-400 font-['Space_Grotesk']">
              Page {currentPage} of {totalPages}
            </span>

            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="p-2 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-300 hover:text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed border border-gray-700"
            >
              <ChevronRight size={24} />
            </motion.button>
          </div>
        )}
      </div>

      <CreateHouseModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSuccess={fetchHouses}
      />
    </motion.div>
  );
};

export default Explorer;