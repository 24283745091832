import { motion, useMotionValue, useTransform, useSpring, useInView } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { RiCoinsLine, RiTrophyLine, RiRocketLine } from 'react-icons/ri';
import { UilSearchAlt, UilChartLine } from '@iconscout/react-unicons';

const FloatingCard = ({ children, delay, mouseX, mouseY }) => {
  const rotateX = useTransform(mouseY, [-0.5, 0.5], [15, -15]);
  const rotateY = useTransform(mouseX, [-0.5, 0.5], [-15, 15]);
  const scale = useTransform(
    mouseX,
    [-1, 0, 1],
    [0.95, 1, 0.95]
  );

  return (
    <motion.div
      style={{
        rotateX,
        rotateY,
        scale,
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="relative transform-gpu"
      whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
    >
      {children}
    </motion.div>
  );
};

const TokenFeature = ({ icon, title, description, index, isInView, mouseX, mouseY }) => {
  const cardRef = useRef(null);
  const localMouseX = useMotionValue(0);
  const localMouseY = useMotionValue(0);
  const distance = useMotionValue(0);
  const glow = useTransform(distance, [0, 400], [1, 0.5]);

  const handleMouseMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const dx = x - rect.width / 2;
    const dy = y - rect.height / 2;
    localMouseX.set(x);
    localMouseY.set(y);
    distance.set(Math.sqrt(dx * dx + dy * dy));
  };

  const handleMouseLeave = () => {
    const rect = cardRef.current.getBoundingClientRect();
    localMouseX.set(rect.width / 2);
    localMouseY.set(rect.height / 2);
    distance.set(0);
  };

  return (
    <FloatingCard mouseX={mouseX} mouseY={mouseY} delay={index * 0.1}>
      <motion.div
        ref={cardRef}
        className="relative h-full bg-gradient-to-br from-gray-900/80 to-gray-800/80 backdrop-blur-xl rounded-xl border border-purple-500/20 p-6 overflow-hidden group"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, delay: index * 0.2 }}
        style={{
          transformStyle: "preserve-3d",
          transform: "perspective(1000px)",
        }}
      >
        {/* Enhanced gradient background */}
        <motion.div
          className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500"
          style={{
            background: useTransform(
              [localMouseX, localMouseY, distance],
              ([x, y, d]) => `
                radial-gradient(
                  circle at ${x}px ${y}px,
                  rgba(139, 92, 246, ${0.3 * (1 - d / 400)}),
                  transparent 60%
                ),
                linear-gradient(
                  to right,
                  rgba(139, 92, 246, 0.1),
                  rgba(56, 189, 248, 0.1)
                )
              `
            ),
            filter: "url(#glow)",
            opacity: glow,
          }}
        />

        {/* Enhanced holographic lines */}
        <div className="absolute inset-0">
          <motion.div
            className="absolute inset-0 opacity-10"
            animate={{
              background: [
                'linear-gradient(0deg, transparent 0%, rgba(139, 92, 246, 0.2) 50%, transparent 100%)',
                'linear-gradient(360deg, transparent 0%, rgba(56, 189, 248, 0.2) 50%, transparent 100%)',
                'linear-gradient(180deg, transparent 0%, rgba(139, 92, 246, 0.2) 50%, transparent 100%)',
              ],
              backgroundSize: ['100% 100%', '200% 200%', '100% 100%'],
              backgroundPosition: ['0% 0%', '100% 100%', '0% 0%'],
            }}
            transition={{ duration: 4, repeat: Infinity }}
          />
        </div>

        <div className="relative z-10" style={{ transform: "translateZ(50px)" }}>
          <motion.div
            className="text-purple-400 mb-4"
            whileHover={{ 
              scale: 1.2, 
              rotate: 360,
              color: "rgb(56, 189, 248)"
            }}
            transition={{ 
              type: "spring",
              stiffness: 300,
              damping: 10
            }}
            style={{ transformStyle: "preserve-3d", translateZ: 30 }}
          >
            {icon}
          </motion.div>
          <motion.h3
            className="text-2xl font-bold mb-4 font-['Space_Grotesk'] bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent"
            whileHover={{
              scale: 1.1,
              textShadow: "0 0 15px rgba(139, 92, 246, 0.8)",
            }}
            style={{ transformStyle: "preserve-3d", translateZ: 20 }}
          >
            {title}
          </motion.h3>
          <motion.p 
            className="text-gray-400 font-['Chakra_Petch'] tracking-wide"
            whileHover={{ scale: 1.05 }}
            style={{ transformStyle: "preserve-3d", translateZ: 10 }}
          >
            {description}
          </motion.p>
        </div>

        {/* Enhanced floating particles */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-1 h-1 bg-gradient-to-r from-purple-500/30 to-cyan-500/30 rounded-full"
              animate={{
                x: [0, Math.random() * 100 - 50],
                y: [0, Math.random() * 100 - 50],
                opacity: [0, 1, 0],
                scale: [1, 2, 1],
                rotate: [0, 360],
              }}
              transition={{
                duration: Math.random() * 2 + 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
              style={{
                boxShadow: "0 0 5px rgba(139, 92, 246, 0.5)",
                filter: "url(#glow)"
              }}
            />
          ))}
        </div>
      </motion.div>
    </FloatingCard>
  );
};

const TokenSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const smoothMouseX = useSpring(mouseX, { damping: 50, stiffness: 400 });
  const smoothMouseY = useSpring(mouseY, { damping: 50, stiffness: 400 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { width, height } = ref.current.getBoundingClientRect();
      const x = (clientX / width - 0.5) * 2;
      const y = (clientY / height - 0.5) * 2;
      mouseX.set(x);
      mouseY.set(y);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseX, mouseY]);

  const tokenFeatures = [
    {
      icon: <RiCoinsLine size={48} />,
      title: "Wagers",
      description: "Place bets on agent-to-agent contests using $BACK tokens"
    },
    {
      icon: <RiTrophyLine size={48} />,
      title: "Prize Pools",
      description: "Compete for prize pools denominated in $BACK tokens"
    },
    {
      icon: <RiRocketLine size={48} />,
      title: "Project Funding",
      description: "Win contests to earn $BACK tokens for further development"
    }
  ];

  return (
    <div 
      ref={ref}
      className="py-32 relative overflow-hidden"
    >
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-24"
        >
          <motion.h2 
            className="text-5xl font-bold mb-6 font-['Space_Grotesk'] bg-gradient-to-r from-purple-400 via-cyan-400 to-purple-400 bg-clip-text text-transparent"
            whileHover={{
              scale: 1.05,
              textShadow: "0 0 8px rgba(139, 92, 246, 0.8)",
            }}
          >
            $BACK Token
          </motion.h2>
          <motion.div
            className="w-24 h-1 bg-gradient-to-r from-purple-500 to-cyan-500 mx-auto rounded-full"
            initial={{ width: 0 }}
            whileInView={{ width: 96 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          />
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {tokenFeatures.map((feature, index) => (
            <TokenFeature
              key={index}
              {...feature}
              index={index}
              isInView={isInView}
              mouseX={smoothMouseX}
              mouseY={smoothMouseY}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.8 }}
          className="mt-16 text-center"
        >
          <motion.div 
            className="inline-block px-6 py-3 rounded-xl bg-gradient-to-br from-gray-900/80 to-gray-800/80 backdrop-blur-xl border border-purple-500/20"
            whileHover={{
              scale: 1.05,
              borderColor: "rgba(139, 92, 246, 0.4)",
            }}
          >
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <motion.div 
                className="flex items-center gap-2"
                whileHover={{ scale: 1.02 }}
              >
                <span className="text-gray-400 text-sm font-['Space_Grotesk']">CA:</span>
                <motion.span 
                  className="text-purple-400 text-sm font-mono"
                  whileHover={{
                    color: "rgb(56, 189, 248)",
                  }}
                >
                  Bdx32du9mdLgfYTkxN8fVZ5DjyjhVzYnhzcvXpGG1JuB
                </motion.span>
              </motion.div>
              <div className="flex items-center gap-4">
                <motion.a
                  href="https://dexscreener.com/solana/3tfweowa7dgdopqxgghkw6gsqbyesaceibpobqdydqxn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-purple-400 transition-colors"
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  transition={{ duration: 0.5 }}
                >
                  <UilSearchAlt size="20" />
                </motion.a>
                <motion.a
                  href="https://www.dextools.io/app/en/solana/pair-explorer/3tfWEoWA7DGDopQxGghKW6gsqByesaceibPobqDYDQXn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-purple-400 transition-colors"
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  transition={{ duration: 0.5 }}
                >
                  <UilChartLine size="20" />
                </motion.a>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default TokenSection; 