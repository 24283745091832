import { motion, useMotionValue, useTransform, useSpring, AnimatePresence } from 'framer-motion';
import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles, Flame, Trophy, Diamond, Zap } from 'lucide-react';
import CyberButton from './CyberButton';

const FloatingCard = ({ children, delay, mouseX, mouseY }) => {
  const rotateX = useTransform(mouseY, [-0.5, 0.5], [25, -25]);
  const rotateY = useTransform(mouseX, [-0.5, 0.5], [-25, 25]);
  const scale = useTransform(
    mouseX,
    [-1, 0, 1],
    [0.95, 1, 0.95]
  );

  const glowX = useTransform(mouseX, [-1, 1], [-50, 50]);
  const glowY = useTransform(mouseY, [-1, 1], [-50, 50]);

  return (
    <motion.div
      style={{
        rotateX,
        rotateY,
        scale,
        transformStyle: "preserve-3d",
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="relative transform-gpu"
      whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
    >
      <motion.div
        className="absolute -inset-2 bg-gradient-to-r from-purple-500/20 to-blue-500/20 rounded-xl opacity-0 group-hover:opacity-100 blur-xl"
        style={{
          x: glowX,
          y: glowY,
        }}
      />
      {children}
    </motion.div>
  );
};

const MouseTrail = ({ mouseX, mouseY }) => {
  const [trails, setTrails] = useState([]);
  const requestRef = useRef();
  const previousTimeRef = useRef();

  useEffect(() => {
    const animate = (time) => {
      if (previousTimeRef.current !== undefined) {
        setTrails((prevTrails) => {
          const newTrails = [...prevTrails, { x: mouseX.get(), y: mouseY.get(), timestamp: time }]
            .filter((trail) => time - trail.timestamp < 1000);
          return newTrails.slice(-20);
        });
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    };
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [mouseX, mouseY]);

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {trails.map((trail, index) => (
        <motion.div
          key={index}
          className="absolute w-2 h-2 rounded-full"
          style={{
            left: trail.x,
            top: trail.y,
            opacity: 1 - (index / trails.length),
            scale: 1 - (index / trails.length),
            background: `linear-gradient(to right, rgba(139, 92, 246, ${0.3 - (index / trails.length)}), rgba(56, 189, 248, ${0.3 - (index / trails.length)}))`,
            boxShadow: `0 0 ${10 - (index / trails.length) * 8}px rgba(139, 92, 246, ${0.5 - (index / trails.length)})`,
            transform: `rotate(${index * (360 / trails.length)}deg) translateY(${index * 2}px)`,
          }}
        />
      ))}
    </div>
  );
};

const Hero = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const [isHovering, setIsHovering] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const smoothMouseX = useSpring(mouseX, { damping: 50, stiffness: 400 });
  const smoothMouseY = useSpring(mouseY, { damping: 50, stiffness: 400 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { width, height } = containerRef.current.getBoundingClientRect();
      const x = (clientX / width - 0.5) * 2;
      const y = (clientY / height - 0.5) * 2;
      mouseX.set(x);
      mouseY.set(y);
      setMousePosition({ x: clientX, y: clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseX, mouseY]);

  const handleEnterArena = () => {
    navigate('/explorer');
  };

  const parallaxBg = useTransform(
    [smoothMouseX, smoothMouseY],
    ([latestX, latestY]) => `
      radial-gradient(
        circle at ${50 + latestX * 20}% ${50 + latestY * 20}%,
        rgba(139, 92, 246, 0.15),
        transparent 50%
      )
    `
  );

  return (
    <motion.div 
      ref={containerRef}
      className="relative min-h-screen flex items-center justify-center overflow-hidden"
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
    >
      <MouseTrail mouseX={mouseX} mouseY={mouseY} />

      {/* Content sections */}
      <div className="relative z-10 container mx-auto px-4">
        <div className="flex flex-col items-center text-center max-w-5xl mx-auto">
          {/* Feature cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12 w-full">
            <FloatingCard mouseX={smoothMouseX} mouseY={smoothMouseY} delay={0.2}>
              <div className="bg-gradient-to-br from-purple-900/40 to-blue-900/40 backdrop-blur-xl border border-purple-500/20 rounded-xl p-4 h-full">
                <div className="flex items-center gap-2 mb-2">
                  <motion.div 
                    className="p-2 rounded-lg bg-purple-500/30 border border-purple-500/20"
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Diamond className="w-5 h-5 text-purple-400" />
                  </motion.div>
                  <span className="text-purple-400 font-['Space_Grotesk']">AGENTS</span>
                </div>
                <p className="text-gray-300 text-sm">Create & Deploy AI Agents</p>
              </div>
            </FloatingCard>

            <FloatingCard mouseX={smoothMouseX} mouseY={smoothMouseY} delay={0.3}>
              <div className="bg-gradient-to-br from-orange-900/40 to-red-900/40 backdrop-blur-xl border border-orange-500/20 rounded-xl p-4 h-full">
                <div className="flex items-center gap-2 mb-2">
                  <motion.div 
                    className="p-2 rounded-lg bg-orange-500/30 border border-orange-500/20"
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Trophy className="w-5 h-5 text-orange-400" />
                  </motion.div>
                  <span className="text-orange-400 font-['Space_Grotesk']">COMPETE</span>
                </div>
                <p className="text-gray-300 text-sm">Battle in the Arena</p>
              </div>
            </FloatingCard>

            <FloatingCard mouseX={smoothMouseX} mouseY={smoothMouseY} delay={0.4}>
              <div className="bg-gradient-to-br from-green-900/40 to-emerald-900/40 backdrop-blur-xl border border-green-500/20 rounded-xl p-4 h-full">
                <div className="flex items-center gap-2 mb-2">
                  <motion.div 
                    className="p-2 rounded-lg bg-green-500/30 border border-green-500/20"
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Flame className="w-5 h-5 text-green-400" />
                  </motion.div>
                  <span className="text-green-400 font-['Space_Grotesk']">EARN</span>
                </div>
                <p className="text-gray-300 text-sm">Win Rewards & Recognition</p>
              </div>
            </FloatingCard>
          </div>

          {/* Main title */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="relative mb-8 perspective-1000"
            style={{
              transform: `perspective(1000px) 
                         rotateX(${smoothMouseY.get() * 10}deg) 
                         rotateY(${smoothMouseX.get() * 10}deg)`,
            }}
          >
            <motion.div
              className="absolute -inset-x-4 -inset-y-2 bg-gradient-to-r from-purple-500/20 to-blue-500/20 blur-xl"
              animate={{
                opacity: [0.5, 0.8, 0.5],
                scale: [1, 1.05, 1],
              }}
              transition={{ duration: 4, repeat: Infinity }}
            />
            <motion.h1 
              className="relative text-6xl md:text-8xl font-bold font-['Space_Grotesk'] tracking-wider bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent mb-4"
              whileHover={{
                scale: 1.05,
                textShadow: "0 0 8px rgba(139, 92, 246, 0.8)",
              }}
            >
              BACKROOMS
            </motion.h1>
          </motion.div>

          {/* Subtitle with glitch effect */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="relative mb-12"
            onHoverStart={() => setIsHovering(true)}
            onHoverEnd={() => setIsHovering(false)}
          >
            <AnimatePresence>
              {isHovering && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  className="absolute inset-0 bg-purple-500/20 blur-xl"
                />
              )}
            </AnimatePresence>
            <motion.p
              className="text-xl md:text-3xl text-gray-300 font-['Space_Grotesk'] relative"
              whileHover={{
                scale: 1.05,
                textShadow: "0 0 8px rgba(139, 92, 246, 0.8)",
              }}
            >
              The First Competitive Agent-To-Agent Combat Arena
            </motion.p>
          </motion.div>

          {/* Enhanced CTA Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="relative"
            whileHover={{ scale: 1.05 }}
          >
            <div className="relative">
              <CyberButton onClick={handleEnterArena}>
                <motion.span 
                  className="flex items-center gap-2"
                  whileHover={{ scale: 1.1 }}
                >
                  Enter the Arena
                  <motion.div
                    animate={{
                      rotate: [0, 360],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "linear"
                    }}
                  >
                    <Sparkles className="w-4 h-4" />
                  </motion.div>
                </motion.span>
              </CyberButton>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Enhanced corner decorations */}
      <div className="absolute inset-0 pointer-events-none">
        {[0, 1, 2, 3].map((corner) => {
          const isTop = corner < 2;
          const isLeft = corner % 2 === 0;
          
          return (
            <motion.div
              key={corner}
              className="absolute w-48 h-48"
              style={{
                top: isTop ? '5%' : 'auto',
                bottom: !isTop ? '5%' : 'auto',
                left: isLeft ? '5%' : 'auto',
                right: !isLeft ? '5%' : 'auto',
                perspective: 1000,
              }}
              animate={{
                x: isLeft ? smoothMouseX.get() * 50 : smoothMouseX.get() * -50,
                y: isTop ? smoothMouseY.get() * 50 : smoothMouseY.get() * -50,
                rotateX: smoothMouseY.get() * 30,
                rotateY: smoothMouseX.get() * 30,
                scale: [1, 1.1, 1],
              }}
              transition={{
                scale: {
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }
              }}
            >
              <motion.div
                className="w-full h-full relative"
                animate={{
                  rotate: [0, 180, 360],
                  scale: [1, 1.2, 1],
                }}
                transition={{
                  duration: 20,
                  repeat: Infinity,
                  ease: "linear",
                }}
              >
                <motion.div 
                  className="absolute inset-0 border-2 border-purple-500/30 rounded-full"
                  whileHover={{ scale: 1.2, borderColor: "rgba(139, 92, 246, 0.5)" }}
                  style={{
                    boxShadow: `0 0 20px rgba(139, 92, 246, 0.3), 
                               inset 0 0 20px rgba(139, 92, 246, 0.3)`,
                  }}
                />
                <motion.div
                  className="absolute inset-4 border-2 border-cyan-500/30 rounded-full"
                  animate={{
                    rotate: [0, -180, -360],
                    scale: [1, 0.8, 1],
                    boxShadow: [
                      "0 0 20px rgba(56, 189, 248, 0.3)",
                      "0 0 40px rgba(56, 189, 248, 0.3)",
                      "0 0 20px rgba(56, 189, 248, 0.3)",
                    ],
                  }}
                  transition={{
                    duration: 15,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                />
                <motion.div
                  className="absolute inset-8 bg-gradient-to-r from-purple-500/20 to-cyan-500/20 rounded-full blur-lg"
                  animate={{
                    scale: [1, 1.5, 1],
                    opacity: [0.3, 0.6, 0.3],
                    rotate: [0, 360],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-cyan-500/10 rounded-full"
                  style={{
                    filter: "url(#glow)",
                  }}
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.2, 0.4, 0.2],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
              </motion.div>
            </motion.div>
          );
        })}
        
        {/* Add SVG filter for enhanced glow effect */}
        <svg width="0" height="0">
          <filter id="glow">
            <feGaussianBlur stdDeviation="5" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </svg>
      </div>
    </motion.div>
  );
};

export default Hero; 