import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Home, ArrowLeft, Loader, Sparkles, Users, Trophy, Zap, Clock, Brain, Shield, Coins, X, AlertCircle } from 'lucide-react';
import { useApi } from '../hooks/useApi';
import { useWeb3 } from '../contexts/Web3Context';
import angel from "../assets/xpaHutel.jpg";

const BettingModal = ({ isOpen, onClose, player, onPlaceBet }) => {
  const [betAmount, setBetAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!betAmount || isNaN(betAmount) || parseFloat(betAmount) <= 0) {
      return;
    }
    setError('');
    setIsSubmitting(true);
    try {
      await onPlaceBet(player.room_id, parseFloat(betAmount));
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Failed to place bet:', error);
      setError('Insufficient $BACK balance. Please make sure you have enough tokens.');
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-md"
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-yellow-600 to-yellow-500 rounded-xl blur opacity-75" />
            
            <div className="relative bg-gray-900 rounded-xl shadow-xl border border-yellow-500/20 p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-bold text-white font-['Space_Grotesk']">Place Your Bet</h2>
                <button onClick={onClose} className="text-gray-400 hover:text-white">
                  <X size={24} />
                </button>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">Betting On</label>
                  <div className="p-4 bg-gray-800/50 rounded-lg border border-gray-700">
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-yellow-500/20 rounded-lg">
                        <Users className="w-5 h-5 text-yellow-400" />
                      </div>
                      <div>
                        <div className="text-white font-semibold">
                          {`${player.wallet_address.slice(0, 6)}...${player.wallet_address.slice(-4)}`}
                        </div>
                        <div className="text-sm text-gray-400 flex items-center gap-2">
                          Room #{player.room_id}
                          <span className="px-2 py-0.5 rounded-full bg-yellow-500/20 text-yellow-300 text-xs">
                            ACTIVE
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-400 mb-2">Bet Amount</label>
                    <div className="relative">
                      <input
                        type="number"
                        value={betAmount}
                        onChange={(e) => setBetAmount(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg focus:border-yellow-500 focus:ring-2 focus:ring-yellow-500/50 text-white placeholder-gray-500"
                        placeholder="Enter amount"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <span className="text-gray-400">$BACK</span>
                      </div>
                    </div>
                  </div>

                  {error && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="p-3 bg-red-500/10 border border-red-500/20 rounded-lg"
                    >
                      <div className="flex items-start gap-2">
                        <AlertCircle className="w-5 h-5 text-red-400 shrink-0 mt-0.5" />
                        <div className="text-red-300 text-sm">{error}</div>
                      </div>
                    </motion.div>
                  )}

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    disabled={isSubmitting || !betAmount}
                    className="w-full relative px-6 py-3 rounded-lg overflow-hidden group disabled:opacity-50"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-yellow-600 to-yellow-500 opacity-80 group-hover:opacity-100 transition-opacity" />
                    <span className="relative flex items-center justify-center gap-2 text-black font-semibold">
                      {isSubmitting ? (
                        <>
                          <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                          >
                            <Sparkles className="w-5 h-5" />
                          </motion.div>
                          Processing...
                        </>
                      ) : (
                        <>
                          <Coins size={18} />
                          Place Bet
                        </>
                      )}
                    </span>
                  </motion.button>
                </form>
              </div>
            </div>
          </motion.div>

          <AnimatePresence>
            {showConfetti && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                className="fixed inset-0 z-50 flex items-center justify-center pointer-events-none"
              >
                <motion.div
                  className="relative bg-gray-900/90 border border-yellow-500/20 rounded-2xl p-12 text-center"
                  initial={{ y: 20 }}
                  animate={{ y: 0 }}
                >
                  <motion.div
                    animate={{ 
                      scale: [1, 1.2, 1],
                      rotate: [0, 10, -10, 0]
                    }}
                    transition={{ duration: 0.5 }}
                    className="mb-4"
                  >
                    <div className="p-4 rounded-full bg-yellow-500/20">
                      <Coins className="w-16 h-16 text-yellow-400" />
                    </div>
                  </motion.div>
                  <h2 className="text-2xl font-bold text-white mb-2">Bet Placed!</h2>
                  <p className="text-yellow-400">Good luck!</p>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const IndividualHouse = () => {
  const { house_id } = useParams();
  const navigate = useNavigate();
  const { address } = useWeb3();
  const { listHouses, enterRoom, payTaskHouseFee, placeBet } = useApi();
  const [house, setHouse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEntering, setIsEntering] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [initializationSuccess, setInitializationSuccess] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isBettingModalOpen, setIsBettingModalOpen] = useState(false);

  const isOwner = address && house?.owner_wallet_address === address;

  const fetchHouseDetails = async () => {
    try {
      setLoading(true);
      const response = await listHouses(house_id);
      if (response && response.task_house) {
        setHouse(response.task_house);
      }
    } catch (error) {
      console.error('Failed to fetch house details:', error);
      navigate('/explorer');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHouseDetails();
  }, [house_id]);

  const handleInitializeHouse = async () => {
    try {
      setIsInitializing(true);
      const response = await payTaskHouseFee(parseInt(house_id, 10), address);
      if (response.status === 200) {
        setInitializationSuccess(true);
        // Refresh house details after initialization
        const updatedResponse = await listHouses(house_id);
        if (updatedResponse && updatedResponse.task_house) {
          setHouse(updatedResponse.task_house);
        }
      }
    } catch (error) {
      console.error('Failed to initialize house:', error);
    } finally {
      setTimeout(() => {
        setIsInitializing(false);
        setInitializationSuccess(false);
      }, 2000);
    }
  };

  const handleEnterHouse = async (roomType = 'simple_prompt') => {
    try {
      setIsEntering(true);
      await enterRoom(parseInt(house_id, 10), roomType);
      setTimeout(() => {
        setIsEntering(false);
        navigate('/account');
      }, 2000);
    } catch (error) {
      console.error('Failed to enter house:', error);
      setIsEntering(false);
    }
  };

  const handlePlaceBet = async (roomId, amount) => {
    try {
      const response = await placeBet(address, roomId, amount);
      if (response.status === 200) {
        // Show success message or refresh data
        fetchHouseDetails();
      }
    } catch (error) {
      console.error('Failed to place bet:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          className="text-purple-500"
        >
          <Loader size={32} />
        </motion.div>
      </div>
    );
  }

  if (!house) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-b from-gray-900 via-purple-900/10 to-gray-900"
    >
      <AnimatePresence>
        {isEntering && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-md"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="relative"
            >
              <motion.div
                className="absolute -inset-4"
                animate={{
                  background: [
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(56, 189, 248, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)"
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              
              <div className="relative bg-gray-900/90 border border-purple-500/20 rounded-2xl p-12 text-center max-w-md">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, rotate: [0, 10, -10, 0] }}
                  transition={{ 
                    scale: { duration: 0.3 },
                    rotate: { duration: 0.5, delay: 0.3 }
                  }}
                  className="mb-6 inline-block"
                >
                  <motion.div
                    animate={{ 
                      boxShadow: [
                        "0 0 20px rgba(139, 92, 246, 0.3)",
                        "0 0 40px rgba(139, 92, 246, 0.6)",
                        "0 0 20px rgba(139, 92, 246, 0.3)"
                      ]
                    }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                    className="p-4 rounded-full bg-purple-500/20"
                  >
                    <Shield className="w-16 h-16 text-purple-400" />
                  </motion.div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <h2 className="text-2xl font-bold text-white font-['Space_Grotesk']">
                    Simulation Access Granted
                  </h2>
                  <p className="text-purple-300 font-['Space_Grotesk']">
                    Initializing neural interface...
                  </p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                  className="mt-6 flex justify-center gap-2"
                >
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
                    className="w-2 h-2 bg-blue-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}

        {initializationSuccess && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-md"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="relative"
            >
              <motion.div
                className="absolute -inset-4"
                animate={{
                  background: [
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(56, 189, 248, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)"
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              
              <div className="relative bg-gray-900/90 border border-purple-500/20 rounded-2xl p-12 text-center max-w-md">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, rotate: [0, 10, -10, 0] }}
                  transition={{ 
                    scale: { duration: 0.3 },
                    rotate: { duration: 0.5, delay: 0.3 }
                  }}
                  className="mb-6 inline-block"
                >
                  <motion.div
                    animate={{ 
                      boxShadow: [
                        "0 0 20px rgba(139, 92, 246, 0.3)",
                        "0 0 40px rgba(139, 92, 246, 0.6)",
                        "0 0 20px rgba(139, 92, 246, 0.3)"
                      ]
                    }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                    className="p-4 rounded-full bg-purple-500/20"
                  >
                    <Coins className="w-16 h-16 text-purple-400" />
                  </motion.div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <h2 className="text-2xl font-bold text-white font-['Space_Grotesk']">
                    House Initialized!
                  </h2>
                  <p className="text-purple-300 font-['Space_Grotesk']">
                    Your task house is now ready for betting
                  </p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                  className="mt-6 flex justify-center gap-2"
                >
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
                    className="w-2 h-2 bg-blue-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        className="fixed inset-0 pointer-events-none"
        animate={{
          background: [
            'radial-gradient(circle at 20% 20%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 80% 80%, rgba(56, 189, 248, 0.15) 0%, transparent 50%)',
            'radial-gradient(circle at 20% 80%, rgba(139, 92, 246, 0.15) 0%, transparent 50%)',
          ]
        }}
        transition={{ duration: 10, repeat: Infinity }}
      />

      <div className="max-w-6xl mx-auto p-8 relative z-10">
        <motion.button
          whileHover={{ scale: 1.05, x: -5 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/explorer')}
          className="mb-8 flex items-center gap-2 text-gray-400 hover:text-white transition-colors group"
        >
          <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
          <span className="font-['Space_Grotesk']">Return to Explorer</span>
        </motion.button>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20 shadow-2xl"
        >
          <div className="flex items-center gap-6 mb-12">
            <motion.div
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
              className="p-4 bg-purple-500/20 rounded-xl border border-purple-500/30"
            >
              <Brain className="w-8 h-8 text-purple-400" />
            </motion.div>
            <div>
              <motion.h1 
                className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 font-['Space_Grotesk'] mb-2"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {house.task_house_name}
              </motion.h1>
              <div className="flex items-center gap-3">
                <motion.div 
                  className="flex items-center gap-1 text-green-400"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />
                  <span className="text-sm">ACTIVE</span>
                </motion.div>
                <div className="w-1 h-1 bg-gray-500 rounded-full" />
                {house.house_settings?.type === 'virtuals' && (
                  <>
                    <motion.div 
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ type: "spring", stiffness: 500, damping: 30 }}
                      className="px-3 py-1.5 rounded-full bg-lime-500/20 border border-lime-500 flex items-center gap-2"
                    >
                      <div className="w-2 h-2 rounded-full bg-lime-500 animate-pulse" />
                      <span className="text-lime-500 text-sm font-['Space_Grotesk'] uppercase tracking-wider">VIRTUALS</span>
                    </motion.div>
                    <div className="w-1 h-1 bg-gray-500 rounded-full" />
                  </>
                )}
                <motion.div 
                  className="flex items-center gap-1 text-purple-400"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <Users size={14} />
                  <span className="text-sm">{house.rooms_count} Active Rooms</span>
                </motion.div>
                <div className="w-1 h-1 bg-gray-500 rounded-full" />
                <motion.div 
                  className="flex items-center gap-1 text-blue-400"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <Trophy size={14} />
                  <span className="text-sm">{house.simulation_stats.success_rate.toFixed(1)}% Success Rate</span>
                </motion.div>
              </div>
            </div>
          </div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-12 relative h-[400px] rounded-xl overflow-hidden group"
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
            
            <motion.img
              src={angel}
              alt="Simulation Preview"
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
            />
            
            <motion.div
              className="absolute inset-0 bg-purple-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20"
            />
            
            <div className="absolute bottom-0 left-0 right-0 p-8 z-30">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="grid grid-cols-3 gap-4"
              >
                <div className="px-4 py-3 bg-purple-500/20 backdrop-blur-sm rounded-xl border border-purple-500/30">
                  <div className="text-purple-300 text-xs mb-1">HOUSE ID</div>
                  <div className="text-white font-['Space_Grotesk'] font-bold">#{house.task_house_id}</div>
                </div>
                <div className="px-4 py-3 bg-blue-500/20 backdrop-blur-sm rounded-xl border border-blue-500/30">
                  <div className="text-blue-300 text-xs mb-1">TOTAL SIMULATIONS</div>
                  <div className="text-white font-['Space_Grotesk'] font-bold">{house.simulation_stats.total_simulations}</div>
                </div>
                <div className="px-4 py-3 bg-green-500/20 backdrop-blur-sm rounded-xl border border-green-500/30">
                  <div className="text-green-300 text-xs mb-1">SUCCESS RATE</div>
                  <div className="text-white font-['Space_Grotesk'] font-bold">{house.simulation_stats.success_rate.toFixed(1)}%</div>
                </div>
              </motion.div>
            </div>
          </motion.div>

          <div className="grid grid-cols-2 gap-6 mb-12">
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-500/10 rounded-xl p-6 border border-purple-500/20"
            >
              <div className="flex items-center gap-3 mb-4">
                <Clock className="w-5 h-5 text-purple-400" />
                <h3 className="text-lg text-purple-400 font-['Space_Grotesk']">Average Iterations</h3>
              </div>
              <div className="text-3xl font-bold text-white font-['Space_Grotesk']">{house.simulation_stats.average_iterations.toFixed(1)}</div>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-blue-500/10 rounded-xl p-6 border border-blue-500/20"
            >
              <div className="flex items-center gap-3 mb-4">
                <Users className="w-5 h-5 text-blue-400" />
                <h3 className="text-lg text-blue-400 font-['Space_Grotesk']">Active Rooms</h3>
              </div>
              <div className="text-3xl font-bold text-white font-['Space_Grotesk']">{house.rooms_count}</div>
            </motion.div>
          </div>

          <div className="space-y-6 mb-12">
            <div>
              <h2 className="text-xl text-purple-400 font-['Space_Grotesk'] mb-4 flex items-center gap-2">
                Task Description <span className="text-sm text-purple-500">//</span>
              </h2>
              <div className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30">
                <p className="text-gray-300 font-['Chakra_Petch'] tracking-wide leading-relaxed">
                  {house.task_description}
                </p>
              </div>
            </div>
          </div>

          {house.is_bettable && (
            <div className="space-y-6 mb-12">
              <div>
                <h2 className="text-xl text-purple-400 font-['Space_Grotesk'] mb-4 flex items-center gap-2">
                  Active Players <span className="text-sm text-purple-500">//</span>
                </h2>
                {Object.keys(house.arena_players).length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {Object.entries(house.arena_players).map(([roomId, walletAddress]) => (
                      <motion.div
                        key={roomId}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        whileHover={{ scale: 1.02 }}
                        className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30 relative overflow-hidden group"
                      >
                        <motion.div
                          className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-blue-500/10 to-purple-500/10"
                          animate={{
                            x: ['-100%', '100%'],
                          }}
                          transition={{
                            duration: 10,
                            repeat: Infinity,
                            ease: 'linear',
                          }}
                        />
                        
                        <div className="relative z-10">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                              <div className="p-2 bg-purple-500/20 rounded-lg">
                                <Users className="w-5 h-5 text-purple-400" />
                              </div>
                              <div>
                                <div className="flex items-center gap-2">
                                  <div className="text-white font-semibold">
                                    {`${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`}
                                  </div>
                                  <motion.div
                                    animate={{ scale: [1, 1.2, 1] }}
                                    transition={{ duration: 2, repeat: Infinity }}
                                    className="w-2 h-2 rounded-full bg-green-400"
                                  />
                                </div>
                                <div className="text-sm text-gray-400 flex items-center gap-2">
                                  Room #{roomId}
                                  <span className="px-2 py-0.5 rounded-full bg-purple-500/20 text-purple-300 text-xs">
                                    COMPETING
                                  </span>
                                </div>
                              </div>
                            </div>
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={() => {
                                setSelectedPlayer({ wallet_address: walletAddress, room_id: roomId });
                                setIsBettingModalOpen(true);
                              }}
                              className="relative px-4 py-2 rounded-lg overflow-hidden group"
                            >
                              <div className="absolute inset-0 bg-gradient-to-r from-yellow-600 to-yellow-500 opacity-80 group-hover:opacity-100 transition-opacity" />
                              <div className="absolute inset-0 bg-gradient-to-r from-yellow-600 to-yellow-500 opacity-0 group-hover:opacity-20 transition-opacity blur-xl" />
                              <span className="relative flex items-center gap-2 text-black font-semibold">
                                <Coins size={16} />
                                Bet
                              </span>
                            </motion.button>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30 text-center"
                  >
                    <div className="flex flex-col items-center gap-4">
                      <motion.div
                        animate={{ 
                          scale: [1, 1.1, 1],
                          rotate: [0, 5, -5, 0]
                        }}
                        transition={{ duration: 4, repeat: Infinity }}
                        className="p-4 bg-purple-500/20 rounded-full"
                      >
                        <Users className="w-8 h-8 text-purple-400" />
                      </motion.div>
                      <div>
                        <p className="text-gray-400 mb-2">No active rooms available for betting</p>
                        <p className="text-sm text-purple-400">Wait for players to join or join yourself!</p>
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          )}

          <div className="flex gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleEnterHouse('simple_prompt')}
              className="flex-1 relative px-8 py-4 rounded-xl overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-80 group-hover:opacity-100 transition-opacity" />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-0 group-hover:opacity-20 transition-opacity blur-xl" />
              <span className="relative text-white font-['Space_Grotesk'] tracking-wider text-lg flex items-center justify-center gap-2">
                ENTER AS PROMPTOOOOR <Sparkles className="w-5 h-5" />
              </span>
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleEnterHouse('virtuals')}
              className="flex-1 relative px-8 py-4 rounded-xl overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-lime-500 to-green-500 opacity-80 transition-opacity" />
              <div className="absolute inset-0 bg-gradient-to-r from-lime-500 to-green-500 opacity-0 group-hover:opacity-20 transition-opacity blur-xl" />
              <span className="relative text-white font-['Space_Grotesk'] tracking-wider text-lg flex items-center justify-center gap-2">
                ENTER AS VIRTUAL <Sparkles className="w-5 h-5" />
              </span>
            </motion.button>
          </div>

          {isOwner && !house.is_task_house_fee_paid && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-6 bg-purple-500/10 rounded-xl p-6 border border-purple-500/20"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-purple-500/20 rounded-lg">
                    <Coins className="w-6 h-6 text-purple-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">Initialize House</h3>
                    <p className="text-gray-400">Make your task house available for betting</p>
                  </div>
                </div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleInitializeHouse}
                  disabled={isInitializing}
                  className="px-6 py-2 bg-purple-500 hover:bg-purple-600 text-white rounded-lg font-semibold transition-colors disabled:opacity-50 flex items-center gap-2"
                >
                  {isInitializing ? (
                    <>
                      <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                      >
                        <Loader size={18} />
                      </motion.div>
                      Initializing...
                    </>
                  ) : (
                    <>
                      <Coins size={18} />
                      Initialize
                    </>
                  )}
                </motion.button>
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>

      <BettingModal
        isOpen={isBettingModalOpen}
        onClose={() => setIsBettingModalOpen(false)}
        player={selectedPlayer}
        onPlaceBet={handlePlaceBet}
      />
    </motion.div>
  );
};

export default IndividualHouse