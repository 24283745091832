import axios from 'axios';
import { useCallback } from 'react';
import { useWeb3 } from '../contexts/Web3Context';

// Mock responses for testing
const MOCK_RESPONSES = {
  'creator/create': { user_uuid: 'mock-uuid-12345' },
  'creator/exists': { user_info: null },
  'creator/history': [],
  'simulation/run': { status: 'started', simulation_id: 'sim-123' },
  'simulation/stop': { status: 'stopped' },
  'simulation/heartbeat': { status: 'running' }
};

const api = axios.create({ 
  baseURL: process.env.REACT_APP_API_URL,
});

// Mock interceptor for testing
api.interceptors.response.use((response) => {
  // Extract the endpoint from the URL
  const endpoint = response.config.url.replace(/^\//, '').split('?')[0];
  
  if (MOCK_RESPONSES[endpoint]) {
    response.data = MOCK_RESPONSES[endpoint];
  }
  
  return response;
});

export const useApi = () => {
  const { address, activeWallet, getAuthKey } = useWeb3();

  // Add headers and params to requests
  api.interceptors.request.use((config) => {
    const userUuid = getAuthKey();
    const formattedAddress = address;

    if (userUuid && formattedAddress) {
      // Add required headers
      config.headers = {
        ...config.headers,
        'user_security_uuid': userUuid,
        'x_wallet_address': formattedAddress,
        'wallet-type': activeWallet
      };
      
      // Add params for GET requests
      if (config.method === 'get') {
        config.params = {
          ...config.params,
          user_uuid: userUuid,
          wallet_type: activeWallet
        };
      }
      // Add params for POST requests
      else if (config.method === 'post') {
        config.data = {
          ...config.data,
          user_uuid: userUuid,
          wallet_type: activeWallet
        };
      }
    }
    return config;
  });

  // Format wallet address based on the active wallet type
  const getFormattedAddress = useCallback(() => {
    if (!address) return null;
    return address;
  }, [address]);

  // Creator routes
  const createCreator = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;
    
    const response = await api.post('/creator/create/', { 
      wallet_address: formattedAddress
    });
    return response.data;
  }, [getFormattedAddress]);

  const checkUserExists = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return false;

    try {
      const response = await api.get('/creator/exists/', {
        params: { wallet_address: formattedAddress }
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }, [getFormattedAddress]);

  const getHistory = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return [];

    const response = await api.get('/creator/history/', {
      params: { wallet_address: formattedAddress }
    });
    return response.data;
  }, [getFormattedAddress]);

  // Simulation routes
  const runSimulation = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/simulation/run/', { 
      wallet_address: formattedAddress,
      room_id: room_id
    });
    return response.data;
  }, [getFormattedAddress]);
  
  const stopSimulation = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/simulation/stop/', { 
      wallet_address: formattedAddress,
      room_id: room_id
    });
    return response.data;
  }, [getFormattedAddress]);
  
  const getHeartbeat = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/simulation/heartbeat/', {
      wallet_address: formattedAddress,
      room_id: room_id
    });
    return response.data;
  }, [getFormattedAddress]);

  // Task House routes
  const createHouse = useCallback(async (task_house_name, task_house_type, task_house_settings, task_description, hours, minutes) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/task-house/create/', { 
      wallet_address: formattedAddress,
      task_house_name,
      task_house_type,
      task_house_settings,
      task_description,
      hours,
      minutes
    });
    return response.data;
  }, [getFormattedAddress]);

  const enterRoom = useCallback(async (task_house_id, room_type = 'simple_prompt') => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/task-house/enter_room/', { 
      wallet_address: formattedAddress,
      task_house_id,
      room_type
    });
    return response.data;
  }, [getFormattedAddress]);

  const leaveRoom = useCallback(async (room_id) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post(`/task-house/leave_room/${room_id}`, {
      wallet_address: formattedAddress
    });
    return response.data;
  }, [getFormattedAddress]);

  const listHouses = useCallback(async (task_house_id = undefined) => {
    try {
      const params = {
        ...(task_house_id ? { task_house_id } : {})
      };
      const response = await api.get('/task-house/list/', { params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }, []);

  const listRooms = useCallback(async () => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return false;

    try {
      const response = await api.get(`/task-house/list_rooms/${formattedAddress}`);
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    }
  }, [getFormattedAddress]);

  const setRoomPrompt = useCallback(async (room_id, room_prompt) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/task-house/simple_prompt_room_settings/', {
      room_id,
      room_prompt,
      wallet_address: formattedAddress
    });
    return response.data;
  }, [getFormattedAddress]);

  const setVirtualsRoomSettings = useCallback(async (room_id, virtual_uid, additional_context) => {
    const formattedAddress = getFormattedAddress();
    if (!formattedAddress) return;

    const response = await api.post('/task-house/virtuals_room_settings/', {
      room_id,
      virtual_uid,
      wallet_address: formattedAddress,
      additional_context
    });
    return response.data;
  }, [getFormattedAddress]);

  // Token-related routes
  const generateDepositWallet = useCallback(async (wallet_address) => {
    try {
      const response = await api.post('/creator/deposit/generate-wallet/', null, {
        params: { wallet_address }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to generate deposit wallet:', error);
      throw error;
    }
  }, []);

  const processDeposit = useCallback(async (wallet_address, token_address) => {
    try {
      const response = await api.post('/creator/deposit/process/', {
        wallet_address,
        token_address
      });
      return response.data;
    } catch (error) {
      console.error('Failed to process deposit:', error);
      throw error;
    }
  }, []);

  const withdrawTokens = useCallback(async (wallet_address, amount, token_address) => {
    try {
      const response = await api.post('/creator/deposit/withdraw/', {
        wallet_address,
        amount,
        token_address
      });
      return response.data;
    } catch (error) {
      console.error('Failed to withdraw tokens:', error);
      throw error;
    }
  }, []);

  const getBalance = useCallback(async (wallet_address) => {
    try {
      const response = await api.get('/creator/balance/', {
        params: { wallet_address }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to get balance:', error);
      throw error;
    }
  }, []);

  // Add new getWalletBets function
  const getWalletBets = useCallback(async (wallet_address) => {
    try {
      const response = await api.get(`/bet/wallet_bets/${wallet_address}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch wallet bets:', error);
      throw error;
    }
  }, []);

  // Betting routes
  const placeBet = useCallback(async (wallet_address, room_id, amount) => {
    try {
      const response = await api.post('/bet/place/', {
        wallet_address,
        room_id,
        amount
      });
      return response.data;
    } catch (error) {
      console.error('Failed to place bet:', error);
      throw error;
    }
  }, []);

  const payTaskHouseFee = useCallback(async (task_house_id, wallet_address) => {
    try {
      const response = await api.post('/task-house/pay_task_house_fee/', {
        task_house_id,
        wallet_address
      });
      return response.data;
    } catch (error) {
      console.error('Failed to pay task house fee:', error);
      throw error;
    }
  }, []);

  const payRoomFee = useCallback(async (room_id, wallet_address) => {
    try {
      const response = await api.post('/task-house/pay_room_fee/', {
        room_id,
        wallet_address
      });
      return response.data;
    } catch (error) {
      console.error('Failed to pay room fee:', error);
      throw error;
    }
  }, []);

  return {
    createCreator,
    checkUserExists,
    getHistory,
    runSimulation,
    stopSimulation,
    getHeartbeat,
    createHouse,
    enterRoom,
    leaveRoom,
    listHouses,
    listRooms,
    setRoomPrompt,
    setVirtualsRoomSettings,
    generateDepositWallet,
    processDeposit,
    withdrawTokens,
    getBalance,
    placeBet,
    payTaskHouseFee,
    payRoomFee,
    getWalletBets
  };
};

export default useApi;