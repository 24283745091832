import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  HelpCircle, AlertCircle, ExternalLink, Brain, Zap, 
  Clock, Trophy, Users, Target, Sparkles, ArrowRight,
  Coins, Lock, Check
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { useWeb3 } from '../contexts/Web3Context';

const StatCard = ({ icon: Icon, label, value, color }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className={`bg-${color}-500/10 rounded-xl p-4 border border-${color}-500/20`}
  >
    <div className="flex items-center gap-3 mb-2">
      <Icon className={`w-4 h-4 text-${color}-400`} />
      <h3 className={`text-sm text-${color}-400 font-['Space_Grotesk']`}>{label}</h3>
    </div>
    <div className="text-xl font-bold text-white font-['Space_Grotesk']">{value}</div>
  </motion.div>
);

const IndividualTask = ({ room, onRoomUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [isPayingFee, setIsPayingFee] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { payRoomFee } = useApi();
  const { address } = useWeb3();

  const handlePayRoomFee = async () => {
    try {
      setIsPayingFee(true);
      const response = await payRoomFee(room.room_id, address);
      if (response.status === 200) {
        setShowSuccessModal(true);
        // Hide success modal after 2 seconds and refresh
        setTimeout(() => {
          setShowSuccessModal(false);
          if (onRoomUpdate) {
            onRoomUpdate();
          }
        }, 2000);
      }
    } catch (error) {
      console.error('Failed to pay room fee:', error);
    } finally {
      setIsPayingFee(false);
    }
  };

  if (!room) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative h-full"
      >
        <div className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20 text-center">
          <Brain size={48} className="text-purple-400 mx-auto mb-4" />
          <h3 className="text-2xl font-bold text-white mb-2">No Room Selected</h3>
          <p className="text-gray-400">Select a room to view its details</p>
        </div>
      </motion.div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative"
      >
        <motion.div 
          className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-8 border border-purple-500/20 relative group"
          whileHover={{ scale: 1.01 }}
          transition={{ duration: 0.2 }}
        >
          {/* Room header */}
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-4">
              <motion.div
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
                className="p-3 bg-purple-500/20 rounded-xl border border-purple-500/30"
              >
                <Brain className="w-6 h-6 text-purple-400" />
              </motion.div>
              <div>
                <h2 className="text-2xl font-bold text-white font-['Space_Grotesk']">
                  Room #{room.room_id}
                </h2>
                <div className="flex items-center gap-4 mt-1">
                  <div className="flex items-center gap-2">
                    <motion.div 
                      className="w-2 h-2 bg-green-400 rounded-full"
                      animate={{
                        scale: [1, 1.2, 1],
                        opacity: [0.5, 1, 0.5]
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity
                      }}
                    />
                    <span className="text-green-400 text-sm font-['Space_Grotesk']">
                      {room.active ? 'ACTIVE' : 'INACTIVE'}
                    </span>
                  </div>
                  {room.task_house_is_bettable && (
                    <motion.div 
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ type: "spring", stiffness: 500, damping: 30 }}
                      className="px-3 py-1.5 rounded-full bg-yellow-500/20 border border-yellow-500 flex items-center gap-2"
                    >
                      <Coins className="w-3 h-3 text-yellow-500" />
                      <span className="text-yellow-500 text-sm font-['Space_Grotesk'] uppercase tracking-wider">BETTABLE</span>
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Room Info */}
          <div className="space-y-6">
            <div className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/30">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="text-purple-300 text-sm font-['Space_Grotesk'] mb-2">HOUSE NAME</h4>
                  <p className="text-gray-300 font-['Space_Grotesk']">{room.task_house_name}</p>
                </div>
                <div>
                  <h4 className="text-purple-300 text-sm font-['Space_Grotesk'] mb-2">ROOM TYPE</h4>
                  <p className="text-gray-300 font-['Space_Grotesk'] uppercase">{room.room_settings.type}</p>
                </div>
                <div>
                  <h4 className="text-purple-300 text-sm font-['Space_Grotesk'] mb-2">SIMULATIONS</h4>
                  <p className="text-gray-300 font-['Space_Grotesk']">{room.simulation_count}</p>
                </div>
                <div>
                  <h4 className="text-purple-300 text-sm font-['Space_Grotesk'] mb-2">FEE STATUS</h4>
                  <div className="flex items-center gap-2">
                    {room.fee_payed ? (
                      <Check className="w-4 h-4 text-green-400" />
                    ) : (
                      <Lock className="w-4 h-4 text-yellow-400" />
                    )}
                    <span className={`font-['Space_Grotesk'] ${room.fee_payed ? 'text-green-400' : 'text-yellow-400'}`}>
                      {room.fee_payed ? 'PAID' : 'UNPAID'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Fee Payment Section */}
            {!room.fee_payed && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-yellow-500/10 rounded-xl p-6 border border-yellow-500/20"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-yellow-500/20 rounded-lg">
                      <Coins className="w-6 h-6 text-yellow-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white mb-1">Enter Competition</h3>
                      <p className="text-gray-400">Pay the room fee to start competing</p>
                    </div>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handlePayRoomFee}
                    disabled={isPayingFee}
                    className="px-6 py-2 bg-yellow-500 hover:bg-yellow-600 text-black rounded-lg font-semibold transition-colors disabled:opacity-50 flex items-center gap-2"
                  >
                    {isPayingFee ? (
                      <>
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        >
                          <Sparkles className="w-5 h-5" />
                        </motion.div>
                        Processing...
                      </>
                    ) : (
                      <>
                        <Coins size={18} />
                        Pay Fee
                      </>
                    )}
                  </motion.button>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </motion.div>

      {/* Success Modal */}
      <AnimatePresence>
        {showSuccessModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-md"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="relative"
            >
              <motion.div
                className="absolute -inset-4"
                animate={{
                  background: [
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(56, 189, 248, 0.3) 0%, transparent 70%)",
                    "radial-gradient(circle at center, rgba(139, 92, 246, 0.3) 0%, transparent 70%)"
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              
              <div className="relative bg-gray-900/90 border border-purple-500/20 rounded-2xl p-12 text-center max-w-md">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, rotate: [0, 10, -10, 0] }}
                  transition={{ 
                    scale: { duration: 0.3 },
                    rotate: { duration: 0.5, delay: 0.3 }
                  }}
                  className="mb-6 inline-block"
                >
                  <motion.div
                    animate={{ 
                      boxShadow: [
                        "0 0 20px rgba(139, 92, 246, 0.3)",
                        "0 0 40px rgba(139, 92, 246, 0.6)",
                        "0 0 20px rgba(139, 92, 246, 0.3)"
                      ]
                    }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                    className="p-4 rounded-full bg-purple-500/20"
                  >
                    <Check className="w-16 h-16 text-purple-400" />
                  </motion.div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <h2 className="text-2xl font-bold text-white font-['Space_Grotesk']">
                    Payment Successful!
                  </h2>
                  <p className="text-purple-300 font-['Space_Grotesk']">
                    You can now start competing in this room
                  </p>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                  className="mt-6 flex justify-center gap-2"
                >
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
                    className="w-2 h-2 bg-blue-500 rounded-full"
                  />
                  <motion.div
                    animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
                    className="w-2 h-2 bg-purple-500 rounded-full"
                  />
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default IndividualTask;