import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import Hero from './landing/Hero';
import Features from './landing/Features';
import TokenSection from './landing/TokenSection';
import FlowDiagram from './FlowDiagram';
import FoundingDocButton from './FoundingDocButton';

const About = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [1, 0.3, 0.3, 1]);

  return (
    <motion.div
      ref={containerRef}
      className="relative min-h-screen bg-gray-900 overflow-hidden"
    >
      {/* Global animated background elements */}
      <motion.div
        className="fixed inset-0 pointer-events-none z-0"
        style={{ opacity }}
      >
        {/* Main gradient background */}
        <motion.div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(circle at 50% 50%, rgba(139, 92, 246, 0.15), transparent 60%)',
            y: backgroundY
          }}
        />
        
        {/* Secondary floating gradients */}
        <motion.div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(circle at 20% 20%, rgba(56, 189, 248, 0.1), transparent 50%)',
            y: useTransform(backgroundY, (v) => -v)
          }}
        />
        <motion.div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(circle at 80% 80%, rgba(139, 92, 246, 0.1), transparent 50%)',
            y: useTransform(backgroundY, (v) => v * 0.5)
          }}
        />

        {/* Animated grid overlay */}
        <motion.div 
          className="absolute inset-0"
          style={{
            background: `linear-gradient(rgba(139,92,246,0.03) 1px,transparent 1px),
                        linear-gradient(90deg,rgba(139,92,246,0.03) 1px,transparent 1px)`,
            backgroundSize: '100px 100px',
            maskImage: 'radial-gradient(ellipse 100% 100% at 50% 50%, black, transparent)',
          }}
        />

        {/* Animated noise texture */}
        <motion.div
          className="absolute inset-0 opacity-[0.015] mix-blend-soft-light"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noise)'/%3E%3C/svg%3E")`,
            transform: 'translate3d(0, 0, 0)',
          }}
        />
      </motion.div>

      {/* Content sections with transparent backgrounds */}
      <div className="relative z-10">
        <Hero />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <Features />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.7 }}
        >
          <TokenSection />
        </motion.div>
        
        <div className="container mx-auto py-24 px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <motion.h2 
              className="text-4xl md:text-5xl font-bold mb-6 font-['Space_Grotesk'] bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent"
              whileHover={{
                scale: 1.05,
                textShadow: "0 0 8px rgba(139, 92, 246, 0.8)",
              }}
            >
              How It Works
            </motion.h2>
            <motion.p 
              className="text-gray-400 max-w-2xl mx-auto font-['Chakra_Petch'] tracking-wide"
              whileHover={{ scale: 1.02 }}
            >
              Understanding the Backrooms AI Process
            </motion.p>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <FlowDiagram />
          </motion.div>

          <motion.div 
            className="mt-16 flex justify-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="relative">
              <motion.div
                className="absolute -inset-4 bg-gradient-to-r from-purple-500/20 to-blue-500/20 blur-xl rounded-xl"
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.3, 0.5, 0.3],
                }}
                transition={{ duration: 3, repeat: Infinity }}
              />
              <div className="relative">
                <FoundingDocButton />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;