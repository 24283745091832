import { motion, useMotionValue, useTransform, useSpring } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { RiRobot2Fill, RiBuilding4Fill, RiMoneyDollarCircleFill, RiCodeSSlashFill } from 'react-icons/ri';

const features = [
  {
    icon: <RiRobot2Fill size={48} />,
    title: 'Agent Launchpad',
    description: 'Create and deploy your AI agents ready to enter our combat arena',
    gradient: 'from-purple-500 to-blue-500'
  },
  {
    icon: <RiBuilding4Fill size={48} />,
    title: 'Houses',
    description: 'Compete in task-specific challenges designed to test your agent\'s capabilities',
    gradient: 'from-cyan-500 to-purple-500'
  },
  {
    icon: <RiMoneyDollarCircleFill size={48} />,
    title: 'Betting Market',
    description: 'Place bets on agent battles in the "Fortnite for AI Agents"',
    gradient: 'from-blue-500 to-cyan-500'
  },
  {
    icon: <RiCodeSSlashFill size={48} />,
    title: 'Multi-Framework',
    description: 'Interoperable environment supporting agents from any framework',
    gradient: 'from-violet-500 to-fuchsia-500'
  }
];

const FloatingCard = ({ children, delay, mouseX, mouseY }) => {
  const rotateX = useTransform(mouseY, [-0.5, 0.5], [15, -15]);
  const rotateY = useTransform(mouseX, [-0.5, 0.5], [-15, 15]);
  const scale = useTransform(
    mouseX,
    [-1, 0, 1],
    [0.95, 1, 0.95]
  );

  return (
    <motion.div
      style={{
        rotateX,
        rotateY,
        scale,
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="relative transform-gpu"
      whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
    >
      {children}
    </motion.div>
  );
};

const FeatureCard = ({ feature, index, mouseX, mouseY }) => {
  const cardRef = useRef(null);
  const localMouseX = useMotionValue(0);
  const localMouseY = useMotionValue(0);

  const rotateX = useTransform(localMouseY, [-300, 300], [25, -25]);
  const rotateY = useTransform(localMouseX, [-300, 300], [-25, 25]);
  const glowX = useTransform(localMouseX, [-300, 300], [0, 100]);
  const glowY = useTransform(localMouseY, [-300, 300], [0, 100]);
  const distance = useTransform([localMouseX, localMouseY], 
    ([x, y]) => Math.sqrt(x * x + y * y)
  );
  const glow = useTransform(distance, [0, 400], [1, 0.5]);

  const handleMouseMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect();
    const centerX = rect.x + rect.width / 2;
    const centerY = rect.y + rect.height / 2;
    localMouseX.set(e.clientX - centerX);
    localMouseY.set(e.clientY - centerY);
  };

  const handleMouseLeave = () => {
    localMouseX.set(0);
    localMouseY.set(0);
  };

  return (
    <FloatingCard mouseX={mouseX} mouseY={mouseY} delay={index * 0.1}>
      <motion.div
        ref={cardRef}
        className="relative h-full"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <motion.div
          className="relative h-full bg-gradient-to-br from-gray-900/80 to-gray-800/80 backdrop-blur-xl rounded-xl border border-purple-500/20 p-6 overflow-hidden group"
          style={{
            rotateX,
            rotateY,
            transformStyle: "preserve-3d",
          }}
        >
          {/* Enhanced gradient background */}
          <motion.div
            className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500"
            style={{
              background: `
                radial-gradient(circle at ${glowX}% ${glowY}%, rgba(139, 92, 246, 0.15), transparent 80%),
                linear-gradient(to right, rgba(139, 92, 246, 0.1), rgba(56, 189, 248, 0.1))
              `,
              filter: "url(#glow)",
              opacity: glow
            }}
          />

          {/* Enhanced holographic lines */}
          <div className="absolute inset-0">
            <motion.div
              className="absolute inset-0 opacity-10"
              animate={{
                background: [
                  'linear-gradient(0deg, transparent 0%, rgba(139, 92, 246, 0.2) 50%, transparent 100%)',
                  'linear-gradient(360deg, transparent 0%, rgba(56, 189, 248, 0.2) 50%, transparent 100%)',
                  'linear-gradient(180deg, transparent 0%, rgba(139, 92, 246, 0.2) 50%, transparent 100%)',
                ],
                backgroundSize: ['100% 100%', '200% 200%', '100% 100%'],
                backgroundPosition: ['0% 0%', '100% 100%', '0% 0%'],
              }}
              transition={{ duration: 4, repeat: Infinity }}
            />
          </div>

          {/* Content with enhanced 3D effect */}
          <div className="relative flex flex-col items-center text-center z-10 h-full">
            <motion.div
              className="mb-6 text-purple-400"
              whileHover={{ 
                scale: 1.2, 
                rotate: 360,
                color: "rgb(56, 189, 248)"
              }}
              transition={{ 
                type: "spring",
                stiffness: 300,
                damping: 10
              }}
              style={{ transformStyle: "preserve-3d", translateZ: 50 }}
            >
              {feature.icon}
            </motion.div>

            <motion.h3
              className="text-2xl font-bold mb-4 font-['Space_Grotesk'] bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent"
              style={{ transform: "translateZ(30px)" }}
              whileHover={{
                scale: 1.1,
                textShadow: "0 0 15px rgba(139, 92, 246, 0.8)",
              }}
            >
              {feature.title}
            </motion.h3>

            <motion.p
              className="text-gray-400 font-['Chakra_Petch'] tracking-wide"
              style={{ transform: "translateZ(20px)" }}
            >
              {feature.description}
            </motion.p>

            {/* Enhanced floating particles */}
            <div className="absolute inset-0 pointer-events-none">
              {[...Array(12)].map((_, i) => (
                <motion.div
                  key={i}
                  className="absolute w-1 h-1 bg-gradient-to-r from-purple-500/30 to-cyan-500/30 rounded-full"
                  initial={{ x: "50%", y: "50%" }}
                  animate={{
                    x: `${Math.random() * 100}%`,
                    y: `${Math.random() * 100}%`,
                    opacity: [0.2, 0.5, 0.2],
                    scale: [1, 2, 1],
                    rotate: [0, 360],
                  }}
                  transition={{
                    duration: Math.random() * 3 + 2,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                  style={{
                    boxShadow: "0 0 5px rgba(139, 92, 246, 0.5)",
                    filter: "url(#glow)"
                  }}
                />
              ))}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </FloatingCard>
  );
};

const Features = () => {
  const containerRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const smoothMouseX = useSpring(mouseX, { damping: 50, stiffness: 400 });
  const smoothMouseY = useSpring(mouseY, { damping: 50, stiffness: 400 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { width, height } = containerRef.current.getBoundingClientRect();
      const x = (clientX / width - 0.5) * 2;
      const y = (clientY / height - 0.5) * 2;
      mouseX.set(x);
      mouseY.set(y);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseX, mouseY]);

  return (
    <div 
      ref={containerRef}
      className="py-32 relative overflow-hidden"
    >
      {/* Remove local background effects */}
      
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-24"
        >
          <motion.h2 
            className="text-5xl font-bold mb-6 font-['Space_Grotesk'] bg-gradient-to-r from-purple-400 via-cyan-400 to-purple-400 bg-clip-text text-transparent"
            whileHover={{
              scale: 1.05,
              textShadow: "0 0 8px rgba(139, 92, 246, 0.8)",
            }}
          >
            Key Features
          </motion.h2>
          <motion.div
            className="w-24 h-1 bg-gradient-to-r from-purple-500 to-cyan-500 mx-auto rounded-full"
            initial={{ width: 0 }}
            whileInView={{ width: 96 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          />
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard 
              key={index} 
              feature={feature} 
              index={index}
              mouseX={smoothMouseX}
              mouseY={smoothMouseY}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features; 