import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Web3Provider } from './contexts/Web3Context';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  useLocation,
  Navigate
} from 'react-router-dom';
import Navbar from './components/Navbar';
import UserAccount from './components/UserAccount';
import Explorer from './components/Explorer';
import History from './components/History';
import About from './components/About';
import '@rainbow-me/rainbowkit/styles.css';
import FoundingDoc from './components/FoundingDoc';
import IndividualHouse from './components/IndividualHouse';
import Sandbox from './components/Sandbox';

// Enhanced page transition variants
const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.98,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 1.02,
  }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5
};

// Enhanced page wrapper with transition effects
const PageWrapper = ({ children }) => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="exit"
      transition={pageTransition}
      className="w-full min-h-screen"
    >
      {/* Background gradient that follows mouse */}
      <motion.div
        className="fixed inset-0 pointer-events-none"
        animate={{
          background: [
            'radial-gradient(circle at 0% 0%, rgba(139, 92, 246, 0.05), transparent 50%)',
            'radial-gradient(circle at 100% 100%, rgba(34, 211, 238, 0.05), transparent 50%)',
            'radial-gradient(circle at 0% 100%, rgba(139, 92, 246, 0.05), transparent 50%)',
          ],
        }}
        transition={{ duration: 10, repeat: Infinity }}
      />

      {/* Content with subtle parallax effect */}
      <motion.div
        className="relative z-10"
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

// Enhanced animated routes with shared layout animations
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route 
          path="/" 
          element={
            <PageWrapper>
              <About />
            </PageWrapper>
          } 
        />
        <Route 
          path="/about" 
          element={
            <PageWrapper>
              <About />
            </PageWrapper>
          } 
        />
        <Route 
          path="/explorer" 
          element={
            <PageWrapper>
              <Explorer />
            </PageWrapper>
          } 
        />
        <Route 
          path="/account" 
          element={
            <PageWrapper>
              <UserAccount />
            </PageWrapper>
          } 
        />
        <Route 
          path="/sandbox" 
          element={
            <PageWrapper>
              <Sandbox />
            </PageWrapper>
          } 
        />
        <Route 
          path="/founding-doc" 
          element={
            <PageWrapper>
              <FoundingDoc />
            </PageWrapper>
          } 
        />
        <Route 
          path="/history" 
          element={
            <PageWrapper>
              <History />
            </PageWrapper>
          } 
        />
        <Route 
          path="/explorer/:house_id" 
          element={
            <PageWrapper>
              <IndividualHouse />
            </PageWrapper>
          } 
        />
      </Routes>
    </AnimatePresence>
  );
};

// Main App component with shared layout
function App() {
  return (
    <Web3Provider>
      <Router>
        <motion.div 
          className="min-h-screen bg-gray-900 relative flex flex-col"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* Persistent navigation */}
          <motion.div
            layoutId="navbar"
            className="fixed top-0 z-50 w-full"
          >
            <Navbar />
          </motion.div>

          {/* Main content area with padding for navbar */}
          <motion.main
            className="flex-1 relative z-10 pt-16"
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <AnimatedRoutes />
          </motion.main>
        </motion.div>
      </Router>
    </Web3Provider>
  );
}

export default App;