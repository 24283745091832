import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { 
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig, useAccount } from 'wagmi';
import { base } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

require('@solana/wallet-adapter-react-ui/styles.css');
require('@rainbow-me/rainbowkit/styles.css');

const Web3Context = createContext(null);

// Cookie and storage helper functions
const setCookie = (name, value, days = 365) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const setUserUuid = (uuid) => {
  localStorage.setItem('user_uuid', uuid);
};

const getUserUuid = () => {
  return localStorage.getItem('user_uuid');
};

const clearUserUuid = () => {
  localStorage.removeItem('user_uuid');
};

// Configure Base chain
const { chains, publicClient } = configureChains(
  [base],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'Backrooms',
  projectId: '08fae043ccbae44767fdb0edaeb859e8',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

export const Web3Provider = ({ children }) => {
  const [isSimulating, setIsSimulating] = useState(false);
  const [messages, setMessages] = useState([]);
  // Initialize activeWallet from cookie or default to 'solana'
  const [activeWallet, setActiveWallet] = useState(() => {
    return getCookie('activeWallet') || 'solana';
  });
  
  // Update cookie when activeWallet changes
  useEffect(() => {
    setCookie('activeWallet', activeWallet);
  }, [activeWallet]);
  
  // Solana Configuration
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    []
  );

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider 
        chains={chains} 
        theme={darkTheme({
          accentColor: '#8b5cf6',
          borderRadius: 'medium',
        })}
      >
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <Web3ContextProvider
                isSimulating={isSimulating}
                setIsSimulating={setIsSimulating}
                messages={messages}
                setMessages={setMessages}
                activeWallet={activeWallet}
                setActiveWallet={setActiveWallet}
              >
                {children}
              </Web3ContextProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

const Web3ContextProvider = ({ children, activeWallet, setActiveWallet, ...contextProps }) => {
  // Solana wallet
  const { publicKey: solanaPublicKey, connected: solanaConnected, signMessage: solanaSignMessage } = useWallet();
  
  // Base wallet
  const { address: baseAddress, isConnected: baseConnected } = useAccount();

  // Current wallet state
  const address = activeWallet === 'solana' ? solanaPublicKey?.toBase58() : baseAddress;
  const isConnected = activeWallet === 'solana' ? solanaConnected : baseConnected;

  const signAuthMessage = async () => {
    const message = `Backrooms`;
    let signature;

    try {
      if (activeWallet === 'solana') {
        const encodedMessage = new TextEncoder().encode(message);
        signature = await solanaSignMessage(encodedMessage);
        return Buffer.from(signature).toString('base64');
      } else {
        // For non-Solana wallets, we don't support signing yet
        throw new Error('Only Solana wallets are supported for signing');
      }
    } catch (error) {
      console.error('Error signing message:', error);
      throw error;
    }
  };

  // Check user existence and create if needed
  useEffect(() => {
    const checkAndCreateUser = async () => {
      if (!isConnected || !address) {
        console.log('No wallet connected or no address available');
        clearUserUuid();
        return;
      }

      try {
        console.log('Checking if user exists for address:', address);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/creator/exists?wallet_address=${address}&wallet_type=${activeWallet}`);
        const data = await response.json();
        console.log('User exists check result:', data);

        if (!data?.user_info) {
          console.log('Creating new creator account');
          const signature = await signAuthMessage();
          console.log('Signature:', signature);
          
          const createResponse = await fetch(`${process.env.REACT_APP_API_URL}/creator/create/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'accept': 'application/json'
            },
            body: JSON.stringify({
              wallet_address: address,
            }),
          });
          console.log('Create response:', createResponse);
          const createResult = await createResponse.json();
          console.log('Creator account created:', createResult);
          
          if (createResult.user_uuid) {
            setUserUuid(createResult.user_uuid);
          }
        }
      } catch (error) {
        console.error('Error in user check/creation:', error);
      }
    };

    checkAndCreateUser();
  }, [address, isConnected, activeWallet]);

  useEffect(() => {
    if (!isConnected) {
      clearUserUuid();
    }
  }, [isConnected]);
  
  const value = {
    address,
    isConnected,
    activeWallet,
    setActiveWallet,
    signAuthMessage,
    getAuthKey: getUserUuid,
    ...contextProps
  };

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};

export const useWeb3 = () => {
  const context = useContext(Web3Context);
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider');
  }
  return context;
};
